import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api-services/api.service';
import { getNameAcronym, renderImg10, showError } from 'src/app/utility/common-logic';
import { AuthData } from 'src/app/utility/model/token-auth';
import { UserPhoto } from 'src/app/utility/model/user-profile';
import { environment } from 'src/environments/environment';
import { DeleteConfirmationComponent } from 'src/app/redeem/modal/delete-confirmation/delete-confirmation.component';

interface DialogData {
  photo: UserPhoto
}

@Component({
  selector: 'app-image-show',
  templateUrl: './image-show.component.html',
  styleUrls: ['./image-show.component.scss'],
})
export class ImageShowComponent implements OnInit {
  imgUrl = environment.url.img;
  authData: Partial<AuthData> = {};
  showEditForm: boolean = false;
  imageCaption: string = '';
  updatingCaption: boolean = false;
  eventImage: UserPhoto;
  deletingImage: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authDataStore: Store<{ authData: AuthData }>,
    private api: ApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ImageShowComponent>
  ) {}

  async ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
    this.eventImage = this.data?.photo;
    this.imageCaption = this.eventImage?.caption;
  }

  userPhoto(photo: UserPhoto) {
    return renderImg10(photo);
  }

  acronym() {
    return getNameAcronym(this.eventImage?.owner?.full_name);
  }

  updateCaption() {
    this.updatingCaption = true;
    this.api
      .editEventImageCaption(this.eventImage?.pk, {
        caption: this.imageCaption,
      })
      .then((res) => {
        if (res) {
          this.eventImage = res;
          this.showEditForm = false;
        }
      })
      .catch((err) => showError(err, this.toastr, this.translate, true))
      .finally(() => (this.updatingCaption = false));
  }

  deleteImage() {
    this.deletingImage = true;
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        message: this.translate.instant('msg.item.deletePhoto'),
        buttonText: {
          ok: this.translate.instant('common.yes'),
          cancel: this.translate.instant('common.no'),
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.api.deleteEventImage(this.eventImage?.pk)
        .catch((err) => showError(err, this.toastr, this.translate))
        .finally(() => {
          this.deletingImage = false;
          this.dialogRef.close();
        });
      } else {
        this.deletingImage = false;
      }
    });
  }
}
