import { Component } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import {environment} from "src/environments/environment";

@Component({
  selector: 'app-filler',
  templateUrl: environment?.components?.common?.filler?.templateUrl,
  styleUrls: ['./filler.component.scss'],
})
export class FillerComponent {
  constructor(public lang: LangService) {}
}
