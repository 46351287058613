import { createAction, props } from '@ngrx/store';
import { FeedType } from 'src/app/utility/enum';
import {
  Badge,
  Nomination,
  NominationCategory,
} from 'src/app/utility/model/nomination';
import { Strength, ValueGroup } from 'src/app/utility/model/user-profile';

export type QuestionAnswer = {
  question_id?: string;
  answer?: string;
  supporting_doc?: string;
  __file?: File;
};

export type Appreciate = {
  mode?: FeedType;
  orgPk?: number;
  nomCat?: NominationCategory | null;
  valueGroup?: ValueGroup | null;
  nomination?: Nomination | null;
  coreValue?: Strength | null;
  description?: string;
  qas?: QuestionAnswer[];
  point?: number;
  customPoint?: boolean;
  messageToReviewer?: string;
  individual?: boolean;
  badges?: Badge[];
  keep?: boolean;
};

export const setAppreciate = createAction(
  'Set Appreciate',
  props<Partial<Appreciate>>()
);
