import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ImgFile } from 'src/app/utility/model/images';

interface DialogData {
  dialogMode: boolean;
  image: ImgFile;
  confirmImage: boolean;
}

@Component({
  selector: 'app-edit-receipt-image',
  templateUrl: './edit-receipt-image.component.html',
  styleUrls: ['./edit-receipt-image.component.scss']
})
export class EditReceiptImageComponent {
  @Output() goBack = new EventEmitter();
  @Output() imagesChange = new EventEmitter<ImgFile>();
  saveBtnDisabled: boolean = true;
  croppedImage = '';
  imageChangedEvent: any = '';
  canvasRotation = 0;
  transform: ImageTransform = {};

  constructor (@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  get disabled() {
    return !this.data.image;
  }

  get disabledClass() {
    return this.disabled ? 'disabled' : '';
  }

  backToCropper() {
    this.data.confirmImage = false;
    this.imageChangedEvent = { target: { files: [this.data.image.imgFile] } }
    this.cropImage(this.data.image)
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.saveBtnDisabled = false;
  }

  imageLoaded() {}

  cropperReady() {}

  loadImageFailed() {}

  cropImage(img: ImgFile) {
    this.croppedImage = img.imgBase64;
    this.imageChangedEvent = { target: { files: [img.imgFile] } };
  }

  saveCroppedImage() {
    this.saveBtnDisabled = true;
    const imgObj = this.data.image;
    imgObj.imgBase64 = this.croppedImage;
    this.imageChangedEvent = null;
    this.cropImage(imgObj);
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
    this.saveBtnDisabled = false;
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
    this.saveBtnDisabled = false;
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
    this.saveBtnDisabled = false;
  }

  proceed() {
    this.data.confirmImage = true;
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }

  confirmImageNProceed() {
    if (!this.data.image) {
      return;
    }
    let item = this.dataURItoBlob(this.data.image.imgBase64);
      const file = new File([item], this.data.image.imgFile.name, {
        type: 'image/png',
      });
      this.data.image.imgFile = file;
    this.imagesChange.emit(this.data.image);
    this.data.confirmImage = false;
  }

}
