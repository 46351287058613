import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RewardSubcategory } from '../../../utility/model/reward-category';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss'],
})
export class SubCategoryComponent implements OnInit {
  @Input() list: RewardSubcategory[] = [];
  @Input() rsc: RewardSubcategory | null | undefined = null;
  @Output() rscChange = new EventEmitter<RewardSubcategory | null>();

  constructor() {}

  ngOnInit() {}

  select(rsc: RewardSubcategory) {
    this.rsc = rsc;
    this.rscChange.emit(rsc);
  }

  boxClass(rsc: RewardSubcategory) {
    return !!this.rsc && this.rsc.pk === rsc.pk
      ? 'box--active'
      : 'box--inactive';
  }

  textClass(rsc: RewardSubcategory) {
    return !!this.rsc && this.rsc.pk === rsc.pk
      ? 'text--active'
      : 'text--inactive';
  }
}
