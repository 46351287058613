import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthData } from 'src/app/utility/model/token-auth';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Reward } from 'src/app/utility/model/reward';
import { AddressChoice } from 'src/app/utility/model/address-choice';
import { ShippingAddressMode } from 'src/app/utility/enum';
import { RedemptionResponse } from 'src/app/utility/model/redemption-response';
import { renderImg1 } from 'src/app/utility/common-logic';
import { environment } from 'src/environments/environment';

interface DialogData {
  reward: Reward;
  category?: string;
  qty: number;
  addressChoice: AddressChoice;
  remainingPoints: number;
  pointsDeducted: number;
  redemptionResponse: RedemptionResponse;
  totalAmountPayable: number;
  isOnlinePayment?: boolean;
  isOnlinePaymentCart?: boolean;
  cartDetails?: any;
  redeemableDetails?: any;
}

@Component({
  selector: 'app-order-placed',
  templateUrl: './order-placed.component.html',
  styleUrls: ['./order-placed.component.scss'],
})
export class OrderPlacedComponent implements OnInit {
  authData: Partial<AuthData> = {};
  cartDetails: any;
  redeemableDetails: any;
  showPoints = environment.settings.showPoints;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private dialogRef: MatDialogRef<OrderPlacedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
  ) {}

  ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
    if (this.data.cartDetails) {
      this.cartDetails = this.data.cartDetails;
    }
    if (this.data?.isOnlinePaymentCart) {
      this.redeemableDetails = this.data?.redeemableDetails;
    }
  }

  icon(reward: Reward | undefined) {
    return renderImg1(reward);
  }

  get hasAddress() {
    return this.data.category === 'Product';
  }

  get addressDisplay1() {
    if (this.data.addressChoice?.mode === ShippingAddressMode.physicalAddress) {
      return (
        this.data.addressChoice.pa?.address_line_1 +
        ' ' +
        this.data.addressChoice.pa?.address_line_2
      );
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return 'SF Store Self-Collection';
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfLocker) {
      return 'SF Locker Self-Collection';
    }
    return '';
  }

  get addressDisplay2() {
    if (this.data.addressChoice?.mode === ShippingAddressMode.physicalAddress) {
      return this.data.addressChoice.pa?.contact_number;
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return this.data.addressChoice?.sfStore;
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfLocker) {
      return this.data.addressChoice?.sfLocker;
    }
    return '';
  }

  async viewOrder() {
    this.dialogRef.close();
    if (
      this.data?.reward?.redemption_type === 'physical_product' ||
      (this.data?.qty === 1 && this.data.redemptionResponse)
    ) {
      await this.router.navigate([
        '/reward/redemption',
        this.data.redemptionResponse.pk,
      ]);
    } else {
      await this.router.navigate(['/rewards/my-wallet']);
    }
  }
}
