import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api-services/api.service';
import {
  FinanceTransactionsResponse, FinanceTransactionsResult,
} from '../../utility/model/finance-transactions-response';
import {
  getNameAcronym, renderImg6, renderImg8, showError, transactionName,
} from '../../utility/common-logic';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {
  FinanceTransactionsPointCategories, FinanceTransactionsPointSummaryResponse,
} from '../../utility/model/finance-transactions-point-summary-response';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-point-detail',
  templateUrl: './point-detail.component.html',
  styleUrls: ['./point-detail.component.scss'],
})
export class PointDetailComponent implements OnInit {
  transactions: FinanceTransactionsResult[] | null | undefined = [];
  activePeriod: 'overall' | 'year' | 'month' = 'overall';
  loading = false;
  year = 0;
  month = 0;
  removeRewardRedemption = environment.settings.removeRewardRedemption;
  showPointCategories = environment.settings.showPointCategories;
  pointDetailTitle = environment.settings.pointDetailTitle;
  pointDetailHeader = environment.settings.pointDetailHeader;
  flexDollarsAvailable?: number | null = 0;
  flexDollarsEarned?: number | null = 0;
  flexDollarsRedeemed?: number | null = 0;
  pointCategories: FinanceTransactionsPointCategories[] = [];
  allowInsufficientPoint = environment.settings.allowInsufficientPoint;

  constructor(
    private router: Router,
    private api: ApiService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) { }

  async ngOnInit() {
    const todayDate = new Date();
    this.year = todayDate.getFullYear();
    this.month = todayDate.getMonth() + 1;
    await this.getPointsSummary();
    if (this.showPointCategories) {
      await this.getPointCategories('year');
    } else {
      await this.getTransactionsHistory(this.activePeriod);
    }
  }

  get title() {
    return this.translate.instant(this.pointDetailTitle);
  }

  get header() {
    return this.translate.instant(this.pointDetailHeader);
  }

  private async getTransactionsHistory(queryParams: string) {
    this.loading = true;
    this.transactions = [];
    await this.api.getTransactionsHistory(queryParams).then((res: FinanceTransactionsResponse) => {
      this.transactions = res.results;
      if (this.removeRewardRedemption) {
        this.transactions = this.transactions.filter((x) => ['Appreciation', 'Strength'].includes(x.reason?.category_name));
      }
    }).catch(err => showError(err, this.toastr, this.translate)).finally(() => (this.loading = false));
  }

  private async getPointsSummary() {
    this.loading = true;
    this.flexDollarsAvailable = 0;
    this.flexDollarsEarned = 0;
    this.flexDollarsRedeemed = 0;

    await this.api.getPointsSummary().then((res: FinanceTransactionsPointSummaryResponse) => {
      this.flexDollarsAvailable = res.remaining_points;
      this.flexDollarsEarned = res.total_points;
      this.flexDollarsRedeemed = res.redeemed_points;
      this.pointCategories = res.point_categories;
    }).catch(err => showError(err, this.toastr, this.translate)).finally(() => (this.loading = false));
  }

  private async getPointCategories(period: string) {
    this.loading = true;
    await this.api.getPointCategories(period).then(res => this.pointCategories = res)
      .catch(err => showError(err, this.toastr, this.translate)).finally(() => this.loading = false);
  }

  trackByPointCategory(index: any, item: any) {
    return index;
  }

  icon(transaction: FinanceTransactionsResult) {
    return renderImg8(transaction);
  }

  icon2(pc: FinanceTransactionsPointCategories) {
    return renderImg6(pc);
  }

  acronym(transaction: FinanceTransactionsResult) {
    return getNameAcronym(transaction.reason.name);
  }

  acronym2(pc: FinanceTransactionsPointCategories) {
    return getNameAcronym(pc.name);
  }

  getTransactionName(transaction: FinanceTransactionsResult) {
    return transactionName(transaction);
  }

  periodClass(period: 'overall' | 'year' | 'month') {
    return this.activePeriod === period ? 'selected' : 'unselected';
  }

  async onChangeTransactionsTab(period: 'overall' | 'year' | 'month') {
    this.activePeriod = period;
    if (this.showPointCategories) {
      if (period === 'overall') {
        await this.getPointsSummary();
      } else if (period === 'year') {
        await this.getPointCategories('year');
      } else {
        await this.getPointCategories('month');
      }
    } else {
      if (period === 'overall') {
        this.getTransactionsHistory(`overall&with_realmoney=false`);
      } else if (period === 'year') {
        this.getTransactionsHistory(`year=${this.year}&with_realmoney=false`);
      } else {
        this.getTransactionsHistory(`month=${this.month}&with_realmoney=false`);
      }
    }
  }

  async open(r: FinanceTransactionsResult) {
    if (r?.context?.redemption) {
      await this.router.navigate(['/reward/redemption', r?.context?.redemption]);
    }
  }

  cursor(r: FinanceTransactionsResult) {
    return r?.context?.redemption ? 'pointer' : 'default';
  }

  getColor(r: FinanceTransactionsResult) {
    if (r?.points > 0) {
      return { color: 'green' };
    }
    if (r?.points < 0) {
      return { color: 'red' };
    }
    return { color: 'black' };
  }

  get btnLabel() {
    return this.translate.instant(this.allowInsufficientPoint ? 'common.seeTransactionHistory' : 'common.viewPointsHistory');
  }
}