<div class="d-flex flex-column box--dialog">
  <div class="text--title">{{ "common.orderProcess" | translate }}</div>
  <div class="text--success">{{ "msg.orderSuc" | translate }}</div>
  <!-- with cart -->
  <div *ngIf="modules.cart && cartDetails">
    <div class="hr"></div>
    <app-cart [editable]="false" [cartDetails]="cartDetails"></app-cart>
  </div>

  <div *ngIf="addressChoice" class="address--section">
    <div class="hr"></div>
    <div class="text--delivery-1">
      {{ "common.deliveryAddress" | translate }}
    </div>
    <div class="text--delivery-2">{{ addressDisplay1 }}</div>
    <div class="text--delivery-2">{{ addressDisplay2 }}</div>
    <div class="text--delivery-2">{{ country }}</div>
  </div>

  <div class="box--proceed">
    <div class="text--proceed text-center">
      {{ "cart.msgOrderProcessMessage" | translate }}
    </div>
  </div>

  <div class="progress mt-1">
    <div
      class="progress-bar progress-bar-striped active progress-bar-animated bg-danger"
      role="progressbar"
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
      style="width: 100%"
    ></div>
  </div>

  <div class="box--view" (click)="viewOrder()">
    <span>{{ "common.backToHome" | translate }}</span>
  </div>
  <div class="box--space"></div>
</div>
