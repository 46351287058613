import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadCount } from 'src/app/utility/enum';

@Component({
  selector: 'app-add-file',
  templateUrl: './add-file.component.html',
  styleUrls: ['./add-file.component.scss'],
})
export class AddFileComponent {
  @Input() nominationFileUpload: FileUploadCount = FileUploadCount.single;
  @Input() files?: File[] = [];
  @Output() filesChange?: EventEmitter<File[]> = new EventEmitter<File[]>();
  progress = 0;
  message = '';
  FileUploadCount = FileUploadCount;

  add(event: any) {
    const selectedFiles: FileList = event.target.files;
    this.progress = 0;

    if (selectedFiles && !!selectedFiles.length) {
      if (this.nominationFileUpload === FileUploadCount.multiple) {
        for (let i = 0; i < selectedFiles.length; i++) {
          const file: File | null = selectedFiles.item(i);
          if (file) {
            this.files.push(file);
          }
        }
      } else {
        this.files = [selectedFiles.item(0)];
      }
    }
    this.filesChange.emit(this.files);
  }

  remove(index: number) {
    this.files.splice(index, 1);
    this.filesChange.emit(this.files);
  }
}
