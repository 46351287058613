import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LangService } from 'src/app/services/lang.service';

interface DialogData {
  src: string;
}

@Component({
  selector: 'app-receipt-attachment-preview',
  templateUrl: './receipt-attachment-preview.component.html',
  styleUrls: ['./receipt-attachment-preview.component.scss']
})
export class ReceiptAttachmentPreviewComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public lang: LangService
  ) { }
}
