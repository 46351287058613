<div class="box__outer">
  <form class="row form-group" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="d-flex form-field">
      <input
        [type]="types[0]"
        class="input-field"
        formControlName="old_password"
        [placeholder]="'authentication.oldPassword' | translate"
      />
      <i
        class="bi bi-eye-fill icon-field"
        [class]="lang.isRTL ? 'rtl-icon-field' : ''"
        [ngClass]="types[0] === 'password' ? 'display-none' : ''"
        (click)="switch(0)"
      ></i>
      <i
        class="bi bi-eye-slash-fill icon-field"
        [class]="lang.isRTL ? 'rtl-icon-field' : ''"
        [ngClass]="types[0] === 'password' ? '' : 'display-none'"
        (click)="switch(0)"
      ></i>
    </div>
    <div class="d-flex form-field">
      <input
        [type]="types[1]"
        class="input-field"
        formControlName="new_password"
        [placeholder]="'authentication.newPassword' | translate"
      />
      <i
        class="bi bi-eye-fill icon-field"
        [class]="lang.isRTL ? 'rtl-icon-field' : ''"
        [ngClass]="types[1] === 'password' ? 'display-none' : ''"
        (click)="switch(1)"
      ></i>
      <i
        class="bi bi-eye-slash-fill icon-field"
        [class]="lang.isRTL ? 'rtl-icon-field' : ''"
        [ngClass]="types[1] === 'password' ? '' : 'display-none'"
        (click)="switch(1)"
      ></i>
    </div>
    <div class="d-flex form-field">
      <input
        [type]="types[2]"
        class="input-field"
        formControlName="confirm_new_password"
        [placeholder]="'authentication.confirmNewPassword' | translate"
      />
      <i
        class="bi bi-eye-fill icon-field"
        [class]="lang.isRTL ? 'rtl-icon-field' : ''"
        [ngClass]="types[2] === 'password' ? 'display-none' : ''"
        (click)="switch(2)"
      ></i>
      <i
        class="bi bi-eye-slash-fill icon-field"
        [class]="lang.isRTL ? 'rtl-icon-field' : ''"
        [ngClass]="types[2] === 'password' ? '' : 'display-none'"
        (click)="switch(2)"
      ></i>
    </div>
    <div class="d-flex txt--hint">
      <img src="assets/img/password-hint.png" />
      {{ 'authentication.minimumLengthPassword' | translate : {n: 8} }}
    </div>
    <div class="d-flex justify-content-end btn--action">
      <span class="cancel--btn" (click)="onClose()">
        {{ 'common.cancel' | translate }}
      </span>
      <button
        class="btn-save"
        type="submit"
        [ngClass]="valid"
        [disabled]="!form.valid"
      >
        <span>{{ 'authentication.changePassword' | translate }}</span>
      </button>
    </div>
  </form>
  <div class="help-block text-danger" *ngIf="errMessage">
    {{ errMessage }}
  </div>
</div>
