<div *ngIf="mode === 'dropdown'" class="dropdown">
  <div
    class="ms-2 box--outer"
    (click)="toggleStyleDisplay(style)"
    (clickOutside)="style.display = 'none'"
  >
    <img src="assets/img/icon_notification.png" width="36px" height="36px" />
    <div
      *ngIf="!!notif?.badge_counter && notif?.badge_counter != 0"
      class="box--notif"
    >
      {{ authData?.notifBadgeCounter }}
    </div>
  </div>
  <app-list
    class="dropdown-content"
    [ngStyle]="style"
    [notifications]="notif?.notifications"
  ></app-list>
</div>
<div
  *ngIf="mode === 'page'"
  class="ms-2 box--outer"
  [routerLink]="['/notification']"
>
  <img
    src="assets/img/icon_notification.png"
    class="d-none d-lg-block d-xl-block d-xxl-block"
    width="36px"
    height="36px"
  />
  <img
    src="assets/img/icon_notification_black.png"
    class="d-block d-lg-none d-xl-none d-xxl-none"
    width="36px"
    height="36px"
  />

  <div *ngIf="!!notif?.badge_counter" class="box--notif">
    {{ authData?.notifBadgeCounter }}
  </div>
</div>
