<table class="table table-hover table-sm">
  <tbody>
    <tr *ngFor="let n of notifications">
      <td>
        <img *ngIf="n.image" [src]="n.image" width="15px" height="15px" />
      </td>
      <td>{{ n.message }}</td>
    </tr>
  </tbody>
</table>
