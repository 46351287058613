<mat-dialog-content class="p-0">
  <div class="position-relative w-100 h-100">
    <div class="d-none d-lg-block position-absolute w-50 h-50 scanner-box">
      <div class="corner top-left"></div>
      <div class="corner top-right"></div>
      <div class="corner bottom-left"></div>
      <div class="corner bottom-right"></div>
    </div>
    <div class="d-none d-lg-block position-absolute w-100 h-100 overlay"></div>

    <zxing-scanner class="w-100 h-100" (scanSuccess)="onCodeResult($event)">
    </zxing-scanner>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="custom-dialog-actions pt-0">
  <div>
    <label class="fs-6 d-none d-lg-block text-danger"
      >{{ "common.qrcodeDesktopNote" | translate }}
    </label>
  </div>
  <div>
    <button mat-dialog-close class="btn btn-danger">
      {{ "common.cancel" | translate }}
    </button>
  </div>
</mat-dialog-actions>
