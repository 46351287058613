<div class="d-flex flex-column">
  <div class="d-flex">
    <input
      #uploader
      class="file-input"
      type="file"
      [multiple]="nominationFileUpload === FileUploadCount.multiple"
      (change)="add($event)"
    />
    <div class="btn--uploader" (click)="uploader.click()">
      <i class="bi bi-plus-lg"></i>
      {{ "common.attachment" | translate }}
    </div>
  </div>
  <div *ngIf="!!files.length" class="card mt-2">
    <div class="card-header txt--files">
      {{ "common.listOfFiles" | translate }}
    </div>
    <ul class="list-group list-group-flush">
      <li
        *ngFor="let file of files; index as i"
        class="list-group-item d-flex justify-content-between txt--files"
      >
        <span>{{ file.name }}</span>
        <i class="bi bi-x-lg icon--delete" (click)="remove(i)"></i>
      </li>
    </ul>
  </div>
</div>
