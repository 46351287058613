import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-load-more',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss']
})
export class LoadMoreComponent {
  @Input() show = false;
  @Input() btnPos = 'flex-row-reverse';
  @Input() class = '';
  @Output() do = new EventEmitter();
}
