import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-float-add-btn',
  templateUrl: './float-add-btn.component.html',
  styleUrls: ['./float-add-btn.component.scss'],
})
export class FloatAddBtnComponent {
  @Input() iconType: string = 'plus';
}
