import { createActionGroup, props } from '@ngrx/store';
import { UserProfile } from 'src/app/utility/model/user-profile';

enum UsersActionTypeE {
  add = 'Add',
  remove = 'Remove',
  clear = 'Clear',
}

export const UsersActions = createActionGroup({
  source: 'Users',
  events: {
    [UsersActionTypeE.add]: props<{ usr: UserProfile }>(),
    [UsersActionTypeE.remove]: props<{ usr: UserProfile }>(),
    [UsersActionTypeE.clear]: props,
  },
});

export const UsersApiActions = createActionGroup({
  source: 'Users API',
  events: {
    'Retrieved List': props<{ users: ReadonlyArray<UserProfile> }>(),
  },
});
