import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpStatusCode
} from '@angular/common/http';
import { catchError, finalize, map, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class LogginInterceptor implements HttpInterceptor {
  token: string | null | undefined;
  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map(event => {
        return event
      }),
      catchError(err => {
        if (err.status === 401) {
          this.router.navigate(['/unauthorized']);
        }

        const error = err.error.message || err.statusText
        return throwError(err);
      }),
      finalize(() => { }),
    )
  }
}
