import { Component, OnInit } from '@angular/core';
import { SharedService } from './services/shared.service';
import { PushNotifyService } from './services/push-notify.service';
import { LangService } from './services/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  app = environment.settings.app;
  applyBgColorFromDjango = environment.settings.applyBgColorFromDjango;
  isPushNotificationSupport = environment.settings.isPushNotificationSupport;

  constructor(
    private push: PushNotifyService,
    public shared: SharedService,
    public lang: LangService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    document.body.setAttribute('id', this.app);

    if (this.isPushNotificationSupport && this.shared.isLogin()) {
      this.push.subscribeToNotifications();
      this.push.showMessages();
    }

    if (this.lang.isRTL) {
      document.body.setAttribute('dir', 'rtl');
      const htmlElement = document.getElementsByTagName('html')[0];
      htmlElement.setAttribute('dir', 'rtl');
    }
  }
}
