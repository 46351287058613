export default {
  common: {
    filler: {
      templateUrl: './filler.component.html',
    },
  },
  header: {
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
  },
  home: {
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
  },
  home_welcomeHome: {
    templateUrl: './welcome-home.component.html',
  },
  about_contactUs: {
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
  },
  about_termsOfUse: {
    templateUrl: './terms-of-use.component.html',
  },
  about_privacy: {
    templateUrl: './privacy.component.html',
  },
  about_userGuide: {
    templateUrl: './user-guide.component.html',
  },
  editProfile: {
    templateUrl: './edit.component.html',
  },
  inputForm: {
    templateUrl: './input-form.component.html',
  },
  loginCarousel: {
    templateUrl: './side-carousel.component.html',
    styleUrls: ['./side-carousel.component.scss'],
  },
  authentication: {
    templateUrl: './authentication.component.html',
  },
  authentication_registration: {
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
  },
  authentication_registrationCompleted: {
    templateUrl: './registration-completed.component.html',
    styleUrls: ['./registration-completed.component.scss'],
  },
  authentication_verifyOtp: {
    templateUrl: './verify-otp.component.html',
    styleUrls: ['./verify-otp.component.scss'],
  },
  authentication_login: {
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
  },
  authentication_forgotPassword: {
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
  },
};
