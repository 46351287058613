<div class="use--ocr">
  <img class="cross--icon" src="assets/img/cross.svg" [mat-dialog-close]/>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <div class="icon--container">
      <span class="query--icon icon"></span>
    </div>
    <div class="txt--title">{{ 'common.receipt.replaceExistingData' | translate }}</div>
    <div class="txt--desc">{{ 'common.receipt.replaceWithOcrData' | translate }}</div>
    <div class="btn--container">
      <div class="btn keep--img" [mat-dialog-close]="'image'">{{ 'common.receipt.skip' | translate }}</div>
      <div class="btn replace--btn" [mat-dialog-close]="'replace'">{{ 'common.receipt.replace' | translate }}</div>
    </div>
  </div>
</div>