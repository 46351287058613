<div class="d-flex mob-flex">
  <div class="div--item mr-20 placeholder-glow">
    <div class="attachment--container">
      <span class="receipt--img w-100 placeholder"></span>
      <div class="d-flex justify-content-between align-items-center mt-3">
        <span class="txt--item placeholder"></span>
        <span class="txt--item placeholder"></span>
      </div>
      <div class="d-flex justify-content-start align-items-center mt-3">
        <div *ngFor="let i of items[0]">
          <span class="attachment placeholder"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="details--item w-100 placeholder-glow">
    <div class="receipt--title w-100 placeholder"></div>
    <div class="txt--item mt-3 placeholder"></div>
    <hr/>
    <div class="receipt--category placeholder"></div>
    <div class="d-flex justify-content-between align-items-center mt-3">
      <span class="txt--item placeholder"></span>
      <span class="txt--item placeholder"></span>
    </div>
    <hr/>
    <div class="d-flex justify-content-between align-items-center mt-3 mb-5">
      <span class="txt--item placeholder"></span>
      <span class="txt--item placeholder"></span>
    </div>
  </div>
</div>