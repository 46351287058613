import { Component } from '@angular/core';

@Component({
  selector: 'app-receipt-details-loader',
  templateUrl: './receipt-details-loader.component.html',
  styleUrls: ['./receipt-details-loader.component.scss']
})
export class ReceiptDetailsLoaderComponent {
  items = [Array(3)];
}
