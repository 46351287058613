export default {
  dev: {
    admin: 'https://skordev.com/admin-dashboard/#/report',
    app: 'https://skordev.com/',
    basePath: 'https://bous-pwa.skordev.com/#/',
    img: 'https://skordev.com',
  },
  prod: {
    admin: 'https://thankabundantly.com/admin-dashboard/#/report',
    app: 'https://thankabundantly.com/',
    basePath: 'https://pwa.thankabundantly.com/#/',
    img: 'https://thankabundantly.com',
  },
};
