import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ImgFile } from '../../utility/model/images';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

interface DialogData {
  dialogMode: boolean;
  images?: ImgFile[];
}

@Component({
  selector: 'app-add-receipt-attachment',
  templateUrl: './add-receipt-attachment.component.html',
  styleUrls: ['./add-receipt-attachment.component.scss']
})
export class AddReceiptAttachmentComponent {
  imgUrl = environment.url.img;
  @Input() isAddPostPoll = '';
  @Input() isOpen = false;
  @Output() goBack = new EventEmitter();
  imageChangedEvent: any = '';
  uploadedFiles: ImgFile[] = [];
  croppedImage = '';
  currentProcessingImg = 0;
  canvasRotation = 0;
  transform: ImageTransform = {};
  @Output() imagesChange = new EventEmitter<ImgFile[]>();
  showImageCropper: boolean = false;
  message: string = '';
  saveBtnDisabled: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              private translate: TranslateService) {}

  ngOnInit() {
    (document.getElementById('uploader') as HTMLElement).click();
  }

  get disabled() {
    return !this.uploadedFiles.length;
  }

  get disabledClass() {
    return this.disabled ? 'disabled' : '';
  }

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler() {
    if ((this.isAddPostPoll && this.isOpen) || !this.isAddPostPoll) {
      if (!!this.croppedImage) {
        this.croppedImage = '';
        this.currentProcessingImg = 0;
        this.imageChangedEvent = '';
      } else if (!!this.uploadedFiles.length) {
        this.uploadedFiles.pop();
      } else {
        this.goBack.emit();
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {}

  cropperReady() {}

  loadImageFailed() {}

  cropImage(img: ImgFile) {
    this.croppedImage = img.imgBase64;
    this.currentProcessingImg = img.imgNo;
    const imgObj = this.uploadedFiles.find((x) => x.imgNo === img.imgNo);
    this.imageChangedEvent = { target: { files: [imgObj.imgFile] } };
  }

  fileChangeEvent(event: any): void {
    const uploadedFilesCount = this.data?.images ? this.data?.images.length : this.uploadedFiles.length;
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const maxAllowedSize = 20 * 1024 * 1024;
      if (file.size <= maxAllowedSize) {
        this.message = "";
        if (String(file.type).includes('application/')) {
          this.showImageCropper = false;
          let img = "";
          if (file.type === 'application/pdf') {
            img = "/assets/img/pdf.png";
          } else {
            img = "/assets/img/doc.png";
          }
          this.uploadedFiles.push({
            imgNo: i + uploadedFilesCount + 1,
            imgBase64: img,
            imgFile: file,
          });
        } else {
          this.showImageCropper = true;
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.uploadedFiles.push({
              imgNo: i + uploadedFilesCount + 1,
              imgBase64: String(reader.result),
              imgFile: file,
            });
            this.cropImage(this.uploadedFiles[0]);
          };
        }
      } else {
        this.message = this.translate.instant('common.receipt.maxSize')
      }
    }
  }

  saveCroppedImage() {
    this.saveBtnDisabled = true;
    const imgObj = this.uploadedFiles.find(
      (x) => x.imgNo === this.currentProcessingImg
    );
    imgObj.imgBase64 = this.croppedImage;
    this.imageChangedEvent = null;
    this.cropImage(imgObj);
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
    this.saveBtnDisabled = false;
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
    this.saveBtnDisabled = false;
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
    this.saveBtnDisabled = false;
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }

  async proceed() {
    if (!this.uploadedFiles.length) {
      return;
    }
    for (let i = 0; i<this.uploadedFiles.length; i++) {
      let item = this.dataURItoBlob(this.uploadedFiles[i].imgBase64);
      const file = new File([item], this.uploadedFiles[i].imgFile.name, { type: 'image/png' });
      this.uploadedFiles[i].imgFile = file;
    }
    this.imagesChange.emit(this.uploadedFiles);
  }
}
