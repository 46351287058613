import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private image: any;

  setImageData(data: any) {
    this.image = data;
  }

  getImageData() {
    return this.image;
  }
}
