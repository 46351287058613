import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ImageTransform, ImageCroppedEvent } from 'ngx-image-cropper';
import { ImgFile } from 'src/app/utility/model/images';
import { environment } from 'src/environments/environment';

interface DialogData {
  dialogMode: boolean;
  image: ImgFile;
}

@Component({
  selector: 'app-add-receipt-image',
  templateUrl: './add-receipt-image.component.html',
  styleUrls: ['./add-receipt-image.component.scss'],
})
export class AddReceiptImageComponent implements OnInit {
  imgUrl = environment.url.img;
  @Input() isAddPostPoll = '';
  @Input() isOpen = false;
  @Output() goBack = new EventEmitter();
  imageChangedEvent: any = '';
  uploadedFiles: ImgFile[] = [];
  croppedImage = '';
  currentProcessingImg = 0;
  canvasRotation = 0;
  transform: ImageTransform = {};
  @Output() imagesChange = new EventEmitter<ImgFile[]>();
  saveBtnDisabled: boolean = true;
  confirmImage: boolean = false;
  originalCropBounds: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  } | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<AddReceiptImageComponent>,
  ) {}

  ngOnInit() {
    (document.getElementById('uploader') as HTMLElement).click();
  }

  get disabled() {
    return !this.uploadedFiles.length;
  }

  get disabledClass() {
    return this.disabled ? 'disabled' : '';
  }

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler() {
    if (!!this.croppedImage) {
      this.croppedImage = '';
      this.currentProcessingImg = 0;
      this.imageChangedEvent = '';
    } else if (!!this.uploadedFiles.length) {
      this.uploadedFiles.pop();
    } else {
      this.goBack.emit();
      this.dialogRef.close();
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    if (!this.originalCropBounds) {
      this.originalCropBounds = {
        x1: event.cropperPosition.x1,
        y1: event.cropperPosition.y1,
        x2: event.cropperPosition.x2,
        y2: event.cropperPosition.y2,
      };
    }

    // Check if the crop bounds have changed
    const cropBoundsChanged =
      this.originalCropBounds.x1 !== event.cropperPosition.x1 ||
      this.originalCropBounds.y1 !== event.cropperPosition.y1 ||
      this.originalCropBounds.x2 !== event.cropperPosition.x2 ||
      this.originalCropBounds.y2 !== event.cropperPosition.y2;

    this.saveBtnDisabled = !cropBoundsChanged;
  }

  imageLoaded() {}

  cropperReady() {}

  loadImageFailed() {}

  cropImage(img: ImgFile) {
    this.croppedImage = img.imgBase64;
    this.currentProcessingImg = img.imgNo;
    const imgObj = this.uploadedFiles.find((x) => x.imgNo === img.imgNo);
    this.imageChangedEvent = { target: { files: [imgObj.imgFile] } };
    this.saveBtnDisabled = true;
    this.originalCropBounds = null;
  }

  fileChangeEvent(event: any): void {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.uploadedFiles.push({
          imgNo: i + 1,
          imgBase64: String(reader.result),
          imgFile: file,
        });
        this.cropImage(this.uploadedFiles[0]);
      };
    }
  }

  saveCroppedImage() {
    this.saveBtnDisabled = true;
    const imgObj = this.uploadedFiles.find(
      (x) => x.imgNo === this.currentProcessingImg,
    );
    imgObj.imgBase64 = this.croppedImage;
    this.imageChangedEvent = null;
    this.cropImage(imgObj);
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
    this.saveBtnDisabled = false;
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
    this.saveBtnDisabled = false;
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
    this.saveBtnDisabled = false;
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }

  async proceed() {
    this.confirmImage = true;
  }

  backToCropper() {
    this.confirmImage = false;
  }

  confirmImageNProceed() {
    if (!this.uploadedFiles.length) {
      return;
    }
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      let item = this.dataURItoBlob(this.uploadedFiles[i].imgBase64);
      const file = new File([item], this.uploadedFiles[i].imgFile.name, {
        type: 'image/png',
      });
      this.uploadedFiles[i].imgFile = file;
    }
    this.imagesChange.emit(this.uploadedFiles);
    this.confirmImage = false;
  }
}
