<div class="box--one">
  <div class="container">
    <div class="px-0 row g-0" *ngIf="!!segments.length">
      <div
        class="col-auto d-flex flex-row"
        *ngFor="let s of segments; index as i"
      >
        <span
          [ngClass]="s.class"
          [routerLink]="Array.isArray(s.link) ? s.link : s.link?.url"
          [queryParams]="Array.isArray(s.link) ? {} : s.link?.params"
          >{{ s.label }}</span
        >
        <div *ngIf="i < segments.length - 1" class="text--one">
          {{ separator }}
        </div>
      </div>
    </div>
  </div>
</div>
