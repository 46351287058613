import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

interface DialogData {
  dialogMode: boolean;
  benefitReceipt: boolean;
  edit: boolean;
}

@Component({
  selector: 'app-add-receipt-success',
  templateUrl: './add-receipt-success.component.html',
  styleUrls: ['./add-receipt-success.component.scss']
})
export class AddReceiptSuccessComponent {
  constructor(private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  goToReceiptList() {
    if (this.data.benefitReceipt) {
      this.router.navigate(['/receipt-list']);
    } else {
      this.router.navigate(['/sales-receipt-list']);
    }
  }
}
