import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/services/lang.service';
import { Notification } from '../../utility/model/notification-list';
import {
  GreetingType,
  NotificationObjectType,
  PeriodType,
} from 'src/app/utility/enum';
import { environment } from 'src/environments/environment';
import {
  compareDate,
  getFullName,
  getNameAcronym,
  isUserSameAsLoggedInUser,
  ordinalSuffix,
} from 'src/app/utility/common-logic';
import { AuthData } from 'src/app/utility/model/token-auth';
import { Store } from '@ngrx/store';
import { PostPollResult } from 'src/app/utility/model/post-poll';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';

interface DialogData {
  item: Notification;
  feedItem: PostPollResult;
}

@Component({
  selector: 'app-notification-greetings',
  templateUrl: './notification-greetings.component.html',
  styleUrl: './notification-greetings.component.scss',
})
export class NotificationGreetingsComponent {
  img = environment.url.img;
  authData: Partial<AuthData> = {};
  NotificationObjectType = NotificationObjectType;
  feeds = environment.settings.feeds;
  GreetingType = GreetingType;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<NotificationGreetingsComponent>,
    private translate: TranslateService,
    public lang: LangService,
    private router: Router,
  ) {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
    console.log(this.data.item);
    console.log(this.data.feedItem);
  }

  get greetingType() {
    return this.data.item?.object_type === NotificationObjectType.birthday
      ? 'birthday'
      : 'anniversary';
  }

  get isSender() {
    return this.data.item?.sender != null ? true : false;
  }

  get icon() {
    return 'icon--' + this.greetingType;
  }

  closeModal() {
    this.dialogRef.close();
  }

  get greetingTitle() {
    const key =
      this.data.item.sender == null
        ? 'common.congratulation.!'
        : 'common.happy' +
          this.greetingType.replace(/^./, this.greetingType[0].toUpperCase());
    return this.translate.instant(key);
  }

  get getFullName() {
    return (
      this.data?.item?.recipient_data?.first_name +
      ' ' +
      this.data?.item?.recipient_data?.last_name
    )?.trim();
  }

  get getProfile() {
    return !!this.data.item?.recipient_data?.profile_pic_url
      ? this.img + this.data.item?.recipient_data?.profile_pic_url
      : false;
  }

  get getFullName1() {
    return (
      this.data?.item?.sender_data?.first_name +
      ' ' +
      this.data?.item?.sender_data?.last_name
    )?.trim();
  }

  get getProfile1() {
    return !!this.data.item?.sender_data?.profile_pic_url
      ? this.img + this.data.item?.sender_data?.profile_pic_url
      : false;
  }

  get acronym() {
    return getNameAcronym(this.getFullName);
  }
  get acronym1() {
    return getNameAcronym(this.getFullName1);
  }

  get iconSmall() {
    if (this.data.item?.object_type === NotificationObjectType.anniversary) {
      return 'assets/img/anniversary-icon.svg';
    } else {
      return 'assets/img/birthday-icon.svg';
    }
  }

  get getImage() {
    return this.data?.feedItem?.ecard
      ? this.data.feedItem?.ecard?.image
      : this.data.feedItem.gif;
  }

  async redirect() {
    this.closeModal();
    const q = {
      queryParams: { feedId: this.data.item.object_id, fromNotification: '1' },
    };
    await this.router.navigate(
      [
        '/' +
          this.feeds.path +
          '/' +
          (this.feeds.postPolls ? 'post-n-polls' : 'appreciation'),
      ],
      q,
    );
  }

  get key() {
    // for Birthday
    let currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
    if (this.data.feedItem?.greeting_info?.type === GreetingType.birthday) {
      if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.past.loggedInUser';
      } else if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.past.othersLogin';
      } else if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.present.loggedInUser';
      } else if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.present.othersLogin';
      } else if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.future.loggedInUser';
      } else if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.future.othersLogin';
      }
    }

    // for anniversary
    if (this.data.feedItem?.greeting_info?.type === GreetingType.anniversary) {
      if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.past.loggedInUser';
      } else if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.past.othersLogin';
      } else if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.present.loggedInUser';
      } else if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.present.othersLogin';
      } else if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.future.loggedInUser';
      } else if (
        compareDate(this.data.feedItem?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.data.feedItem?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.future.othersLogin';
      }
    }
    return '';
  }

  get anniversaryDuration() {
    if (this.data?.feedItem?.greeting_info?.type === GreetingType.anniversary) {
      return Number(this.data?.feedItem?.greeting_info?.years_completed);
    }
    return null;
  }

  get ordinalSuffix() {
    return this.lang.lang === 'en'
      ? ordinalSuffix(this.anniversaryDuration)
      : '';
  }
}
