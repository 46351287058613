import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LangService } from '../../services/lang.service';
import { LanguageMode } from 'src/app/utility/enum';
import { toggleStyleDisplay } from 'src/app/utility/common-logic';

@Component({
  selector: 'app-language-change',
  templateUrl: './language-change.component.html',
  styleUrls: ['./language-change.component.scss'],
})
export class LanguageChangeComponent implements OnInit {
  @Input() mode = LanguageMode.iconDropdown;
  @Input() withPadding = '';
  @Input() showNotif = false;
  @Input() isIconSVG = false;
  LanguageMode = LanguageMode;
  toggleStyleDisplay = toggleStyleDisplay;
  style: Partial<CSSStyleDeclaration> = { display: 'none' };
  languageAvailable = environment.settings.languageAvailable;

  constructor(public langS: LangService) {}

  ngOnInit() {
    this.langS.showNotif = this.showNotif;
  }

  switch() {
    const index = this.languageAvailable.findIndex(
      (x: string) => x === this.langS.lang,
    );
    const nextIndex = (index + 1) % this.languageAvailable.length;
    this.langS.lang = this.languageAvailable.at(nextIndex);
  }

  selectedClass(l: string, prefix = '') {
    return this.langS.lang === l ? prefix + 'selected' : '';
  }

  get btnClass() {
    return !this.withPadding ? 'icon-no-btn' : 'icon-btn';
  }
}
