<div class="d-flex flex-column box--dialog">
  <div class="text--title">{{ "common.orderSummary" | translate }}</div>
  <div class="hr"></div>

  <!-- cart details -->
  <div *ngIf="cartDetails">
    <app-cart
      [editable]="false"
      [cartDetails]="cartDetails"
      (receivedCartChanges)="onShowCartSummary($event)"
    ></app-cart>
  </div>

  <div class="row calculations mt-4">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row justify-content-between box--points-2">
        <div class="box--points-2-text">
          {{ "cart.cartTotal" | translate }}
        </div>
        <div class="box--points-2-num text--yellow">
          {{ cartDetails?.amount_payable | number }}
        </div>
      </div>
    </div>

    <div class="hr"></div>
    <div class="d-flex flex-column mt-3">
      <div class="d-flex flex-row justify-content-between box--points-2">
        <div class="box--points-2-text">
          {{ "common.totalPointsAvailable" | translate }}
        </div>
        <div class="box--points-2-num text--yellow">
          {{ cartDetails?.user_points | number }}
        </div>
      </div>
    </div>

    <div *ngIf="cartDeliveryFee && cartDeliveryFee != 0">
      <div class="hr"></div>
      <div class="d-flex flex-column mt-3">
        <div class="d-flex flex-row justify-content-between box--points-2">
          <div class="box--points-2-text">
            {{ "common.deliveryFee" | translate }}
          </div>
          <div class="box--points-2-num text--yellow">
            {{ cartDeliveryFee | number }}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isOnlinePaymentCart">
      <div class="hr"></div>
      <div class="d-flex flex-column mt-3">
        <div class="d-flex flex-row justify-content-between box--points-2">
          <div class="box--points-2-text">
            {{ "common.balancePointsNeeded" | translate }}
          </div>
          <div class="box--points-2-num text--yellow">
            {{ balancePointsNeededCart | number }}
          </div>
        </div>
        <div class="box--qty-1">{{ "msg.exceededBalance" | translate }}</div>
      </div>
    </div>

    <div class="d-flex flex-column mt-2" *ngIf="!isOnlinePaymentCart">
      <div
        class="d-flex flex-row justify-content-between box--points-2 box--total-amount"
      >
        <div class="box--points-2-text">
          {{ "common.totalPointsPayable" | translate }}
        </div>
        <div class="box--points-2-num text--yellow">
          {{ totalPointsPayable | number }}
        </div>
      </div>
    </div>

    <div *ngIf="isOnlinePaymentCart" class="box__online-payment">
      <div class="hr"></div>
      <div class="d-flex flex-column mt-3">
        <div class="d-flex flex-row justify-content-between box--points-2">
          <div class="box--points-2-text">
            {{ "common.adminFee" | translate }}
          </div>
          <div class="box--points-2-num text--yellow">
            {{ adminFee | number }}
          </div>
        </div>
        <div class="box--qty-1">
          {{ adminFeePercentage | number : "1.2-2"
          }}{{ "msg.pointNeeded" | translate }}
        </div>
      </div>
      <div
        class="d-flex flex-row justify-content-between box--points-2 box--total-amount"
      >
        <div class="box--points-2-text">
          {{ "common.totalAmountPayable" | translate }}
        </div>
        <div class="box--points-2-num">
          {{ authData?.tokenAuth?.currency }} {{ totalAmountPayable | number }}
        </div>
      </div>
    </div>

    <div *ngIf="addressChoice" class="delivery-address">
      <div class="hr"></div>
      <div class="text--delivery-1">
        {{ "common.deliveryAddress" | translate }}
      </div>
      <div class="text--delivery-2">{{ addressDisplay1 }}</div>
      <div class="text--delivery-2">{{ addressDisplay2 }}</div>
      <div class="text--delivery-2">{{ country }}</div>
    </div>

    <div class="box--proceed">
      <div class="text--proceed text-center">
        {{ "msg.placeOrder" | translate }}
      </div>
    </div>
    <div
      class="box--place"
      (click)="onPlaceOrder()"
      *ngIf="!isOnlinePaymentCart"
    >
      <span>{{ "common.placeYourOrder" | translate }}</span>
    </div>
    <div
      *ngIf="isOnlinePaymentCart"
      class="box--place"
      (click)="onProceedPayment()"
    >
      <span>{{ "common.proceedToPayment" | translate }}</span>
    </div>
    <div class="box--space"></div>
  </div>
</div>
