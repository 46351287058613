import { createReducer, on } from '@ngrx/store';
import { FeedType } from 'src/app/utility/enum';
import { Appreciate, setAppreciate } from './appreciate.actions';

export const initialState: Appreciate = {
  mode: FeedType.all,
  orgPk: null,
  nomCat: null,
  valueGroup: null,
  nomination: null,
  coreValue: null,
  description: '',
  qas: [],
  point: 0,
  customPoint: false,
  messageToReviewer: '',
  individual: false,
  badges: [],
  keep: false,
};

export const appreciateReducer = createReducer(
  initialState,
  on(
    setAppreciate,
    (
      state: Appreciate,
      payload: Partial<Appreciate>
    ) => ({
      ...state,
      ...payload,
    })
  )
);
