<div class="header-top-menu">
  <div class="d-flex flex-row side--menu">
    <div
      *ngIf="modules.appreciate && showAppreciation"
      class="d-flex flex-row align-items-center py-2 side--menu-item"
      [ngClass]="is('settings') ? 'selected' : ''"
      [routerLink]="['/appreciate']"
    >
      <div class="d-flex" style="position: relative">
        <span class="icon--appreciate"></span>
        <img class="icon--love" src="assets/img/appreciate-love.svg" />
      </div>
      <span [ngClass]="fontClass('appreciate')">
        {{ "common.appreciate" | translate }}
      </span>
    </div>
    <div
      *ngIf="modules.home"
      class="d-flex flex-row align-items-center side--menu-item"
      [ngClass]="is('home') ? 'selected' : ''"
      [routerLink]="['/home']"
      (click)="onClickTopMenu('home')"
    >
      <span class="icon icon--home" [ngClass]="selectedClass('home')"></span>
      <span [ngClass]="fontClass('home')">{{ "common.home" | translate }}</span>
      <div *ngIf="is('home')" class="active--dot"></div>
    </div>
    <div
      *ngIf="modules.feeds && showFeeds"
      class="d-flex flex-row align-items-center side--menu-item"
      [ngClass]="is('my-feed') ? 'selected' : ''"
      [routerLink]="['/my-feed']"
      (click)="onClickTopMenu('my-feed')"
    >
      <span
        class="icon icon--my-feed"
        [ngClass]="selectedClass('my-feed')"
      ></span>
      <span [ngClass]="fontClass('my-feed')">
        {{ "common.feeds" | translate }}
      </span>
      <div *ngIf="is('my-feed')" class="active--dot"></div>
    </div>
    <div
      *ngIf="modules.rewards && showRewards"
      class="d-flex flex-row align-items-center side--menu-item"
      [ngClass]="is('rewards') ? 'selected' : ''"
      [routerLink]="['/rewards']"
      (click)="onClickTopMenu('rewards')"
    >
      <span
        class="icon icon--rewards"
        [ngClass]="selectedClass('rewards')"
      ></span>
      <span [ngClass]="fontClass('rewards')">
        {{ "common.reward.n" | translate }}
      </span>
      <div *ngIf="is('rewards')" class="active--dot"></div>
    </div>
    <div
      *ngIf="modules.myWallet"
      class="d-flex flex-row align-items-center side--menu-item"
      [ngClass]="is('my-wallet') ? 'selected' : ''"
      [routerLink]="['/rewards/my-wallet']"
      (click)="onClickTopMenu('my-wallet')"
    >
      <span
        class="icon icon--my-wallet"
        [ngClass]="selectedClass('my-wallet')"
      ></span>
      <span [ngClass]="fontClass('my-wallet')">
        {{ "common.myWallet" | translate }}
      </span>
      <div *ngIf="is('my-wallet')" class="active--dot"></div>
    </div>
    <div
      *ngIf="isCalendarEnabled"
      class="d-flex flex-row align-items-center side--menu-item"
      [ngClass]="is('calendar') ? 'selected' : ''"
      [routerLink]="['/calendar']"
      (click)="onClickTopMenu('calendar')"
    >
      <span
        class="icon icon--calendar"
        [ngClass]="selectedClass('calendar')"
      ></span>
      <span [ngClass]="fontClass('calendar')">
        {{ "common.calendar" | translate }}
      </span>
      <div *ngIf="is('calendar')" class="active--dot"></div>
    </div>
    <div
      *ngIf="modules.pointsHistory"
      class="d-flex flex-row align-items-center side--menu-item"
      [ngClass]="is('point-history') ? 'selected' : ''"
      [routerLink]="['/point-history']"
      [queryParams]="{ point_type: 'all', point_category: 'all' }"
      (click)="onClickTopMenu('point-history')"
    >
      <span
        class="icon icon--point-history"
        [ngClass]="selectedClass('point-history')"
      ></span>
      <span [ngClass]="fontClass('point-history')">
        {{ "common.pointsHistory" | translate }}
      </span>
      <div *ngIf="is('point-history')" class="active--dot"></div>
    </div>
  </div>
</div>
