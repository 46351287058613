import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthData } from 'src/app/utility/model/token-auth';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { OrderPlacedComponent } from '../order-placed/order-placed.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api-services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { showError } from 'src/app/utility/common-logic';
import { ShippingAddressMode } from 'src/app/utility/enum';
import { OrderDetail } from 'src/app/utility/model/delivery-fee';

@Component({
  selector: 'app-order-process',
  templateUrl: './order-process.component.html',
  styleUrls: ['./order-process.component.scss'],
})
export class OrderProcessComponent implements OnInit {
  authData: Partial<AuthData> = {};
  cartDetails: any;
  responseData: any;
  modules = environment.settings.modules;
  loading = true;
  intervalRunner: any;
  addressChoice: any;
  address: any;
  addressType: any;
  contactNumber: any;
  isCeleryApiInProgress: boolean = false;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private dialogRef: MatDialogRef<OrderProcessComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private api: ApiService,
    private shared: SharedService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
    if (this.modules.cart) {
      this.cartDetails = this.data.cartDetails;
      this.addressChoice = this.data?.addressChoice;
      this.address = this.data?.address;
      this.addressType = this.data?.addressType;
      this.contactNumber = this.data?.contactNumber;
      this.responseData = this.data.responseData;
      if (this.data.responseData.task_id) {
        this.intervalRunner = setInterval(() => this.onCeleryProgress(), 2000);
      }
    }
  }

  get addressDisplay1() {
    if (this.data.addressChoice?.mode === ShippingAddressMode.physicalAddress) {
      return (
        this.data.addressChoice.pa?.address_line_1 +
        ' ' +
        this.data.addressChoice.pa?.address_line_2
      );
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return 'SF Store Self-Collection';
    } else {
      return 'SF Locker Self-Collection';
    }
  }

  get addressDisplay2() {
    if (this.data.addressChoice?.mode === ShippingAddressMode.physicalAddress) {
      return this.data.addressChoice.pa?.contact_number;
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfStore) {
      return this.data.addressChoice?.sfStore;
    } else if (this.data.addressChoice?.mode === ShippingAddressMode.sfLocker) {
      return this.data.addressChoice?.sfLocker;
    }
    return '';
  }

  get country() {
    if (this.data.addressChoice?.mode === ShippingAddressMode.physicalAddress) {
      return this.data.addressChoice.pa?.country;
    }
    return this.authData?.tokenAuth?.organization_details?.country;
  }

  viewOrder() {
    clearInterval(this.intervalRunner);
    this.dialogRef.close();
    localStorage.removeItem('orderDetails');
    this.router.navigate(['/rewards/my-wallet']);
  }

  async onCeleryProgress() {
    if (this.isCeleryApiInProgress) {
      return;
    }
    this.isCeleryApiInProgress = true;
    await this.api
      .checkOrderCeleryProgress(this.data.responseData.task_id)
      .then((res: any) => {
        if (res?.complete) {
          clearInterval(this.intervalRunner);
          this.dialogRef.close();
          if (res?.success) {
            this.openOrderPlaceModal();
          } else {
            showError(res?.result, this.toastr, this.translate);
          }
        }
      })
      .catch((err) => {
        showError(err, this.toastr, this.translate);
        clearInterval(this.intervalRunner);
      })
      .finally(() => {
        this.isCeleryApiInProgress = false;
        this.shared.spinner = false;
      });
  }

  openOrderPlaceModal() {
    const orderDetails: Partial<OrderDetail> = JSON.parse(
      localStorage.getItem('orderDetails') as string,
    );
    // orderDetails are null when coming without Payment
    if (orderDetails) {
      this.dialog.open(OrderPlacedComponent, {
        disableClose: true,
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: {
          isOnlinePaymentCart: true,
          cartDetails: orderDetails.cartDetails,
          redeemableDetails: orderDetails.redeemableDetails,
          addressChoice: this.addressChoice,
          address: this.address,
          addressType: this.addressType,
          pointsDeducted: this.data.pointsDeducted,
        },
      });
      localStorage.removeItem('orderDetails');
    } else {
      this.dialog.open(OrderPlacedComponent, {
        disableClose: true,
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: {
          isOnlinePaymentCart: false,
          cartDetails: this.cartDetails,
          redeemableDetails: this.responseData,
          addressChoice: this.addressChoice,
          address: this.address,
          addressType: this.addressType,
          pointsDeducted: this.data.pointsDeducted,
        },
      });
    }
  }
}
