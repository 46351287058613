<div class="modal--content">
  <div class="heading">
    {{ "consentBox.heading" | translate }}
    <hr />
  </div>

  <div class="main--content">
    <ol>
      <li>
        {{ "consentBox.paragraphOne._" | translate }}
        <ul class="list-unstyled">
          <li
            [innerHTML]="'consentBox.paragraphOne.innerItem_1' | translate"
          ></li>
          <li
            [innerHTML]="'consentBox.paragraphOne.innerItem_2' | translate"
          ></li>
          <li
            [innerHTML]="'consentBox.paragraphOne.innerItem_3' | translate"
          ></li>
          <li
            [innerHTML]="'consentBox.paragraphOne.innerItem_4' | translate"
          ></li>
        </ul>
      </li>
      <li [innerHTML]="'consentBox.paragraphSecond' | translate"></li>
    </ol>
    <div [innerHTML]="'consentBox.linksParagraph' | translate"></div>
    <div class="last-para mt-2">
      {{ "consentBox.paragraphLast" | translate }}
    </div>
    <div
      class="small-para mt-2"
      [innerHTML]="'consentBox.smallParagraph' | translate"
    ></div>
  </div>

  <div class="bottons-section mt-4">
    <button class="disagree-btn" (click)="closeModal()">
      {{ "consentBox.disagreeButton" | translate }}
    </button>
    <button class="agree-btn" (click)="onAgree()">
      {{ "consentBox.agreeButton" | translate }}
    </button>
  </div>
</div>
