import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Appreciate } from 'src/app/state/appreciate/appreciate.actions';
import { MatDialog } from '@angular/material/dialog';
import { AmplifyModalComponent } from './amplify-modal/amplify-modal.component';
import { AmplifyTypes } from 'src/app/utility/enum';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-amplify',
  templateUrl: './amplify.component.html',
  styleUrls: ['./amplify.component.scss']
})
export class AmplifyComponent implements OnInit {
  appreciate: Partial<Appreciate>;
  @Input() desc: string;
  @Input() title: string = '';
  @Input() amplifyType = AmplifyTypes.appreciate;
  @Output() descChange = new EventEmitter<string>();
  @Output() titleChange = new EventEmitter<string>();
  @Output() pollOptions = new EventEmitter<[string]>();

  constructor(
    private appreciateStore: Store<{ appreciate: Appreciate }>,
    private dialog: MatDialog,
    public lang: LangService
  ) {}

  ngOnInit() {
    this.appreciateStore
      .select('appreciate')
      .subscribe((res: Partial<Appreciate>) => (this.appreciate = res));
  }

  openModal() {
    const dialogRef = this.dialog.open(AmplifyModalComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: { desc: this.desc, coreValue: this.appreciate?.coreValue, title: this.title, amplifyType: this.amplifyType },
    });
    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        this.desc = res?.desc;
        this.title = res?.title;
        this.descChange.emit(res?.desc);
        this.titleChange.emit(res?.title);
        this.pollOptions.emit(res?.pollOptions);
      }
    });
  }
}
