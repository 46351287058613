import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ECardCategoryResult } from '../../utility/model/e-card';
import { ApiService } from '../../services/api-services/api.service';
import { Image } from '../../utility/model/post-poll';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { showError } from 'src/app/utility/common-logic';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

interface DialogData {
  dialogMode: boolean;
  eCard?: Image;
}

@Component({
  selector: 'app-add-ecard',
  templateUrl: './add-ecard.component.html',
  styleUrls: ['./add-ecard.component.scss'],
})
export class AddEcardComponent implements OnInit {
  @Input() isAddPostPoll = '';
  @Input() isOpen = false;
  @Output() goBack = new EventEmitter();
  key = '';
  eCardCats: ECardCategoryResult[] = [];
  filter: ECardCategoryResult | undefined;
  eCards: Image[] = [];
  eCard: Image | undefined;
  prevECard: Image | undefined;
  @Output() eCardChange = new EventEmitter<Image>();
  loading = false;

  constructor(
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  async ngOnInit() {
    if (!!this.data?.eCard) {
      this.eCard = this.data?.eCard;
    }
    this.prevECard = this.eCard;
    await this.getECardCats();
    await this.getECards();
  }

  onGoBack() {
    this.eCard = this.prevECard;
    this.goBack.emit();
  }

  @HostListener('document:keyup.escape', ['$event'])
  onKeydownHandler() {
    if ((this.isAddPostPoll && this.isOpen) || !this.isAddPostPoll) {
      if (
        this.eCard === this.prevECard ||
        this.eCard?.pk === this.prevECard.pk
      ) {
        this.goBack.emit();
      } else {
        this.eCard = this.prevECard;
      }
    }
  }

  async getECardCats() {
    this.loading = true;
    await this.api
      .getECardCategories()
      .then((res) => (this.eCardCats = res.results))
      .catch((err) => showError(err, this.toastr, this.translate))
      .finally(() => (this.loading = false));
  }

  async getECards(pk: number | null = null) {
    this.loading = true;
    await this.api
      .getECards(pk)
      .then((res) => (this.eCards = res.results))
      .catch((err) => showError(err, this.toastr, this.translate))
      .finally(() => (this.loading = false));
  }

  selectFilter(cat: ECardCategoryResult) {
    if (!!this.filter && this.filter?.pk === cat.pk) {
      this.filter = null;
    } else {
      this.filter = cat;
    }
  }

  get eCards2() {
    return this.eCards.filter((x: Image) => {
      return (
        (!this.key ||
          (!!this.key &&
            (x.name.toLowerCase().includes(this.key.toLowerCase()) ||
              x.category_name.toLowerCase().includes(this.key.toLowerCase()) ||
              x.tags.some((x) => x.includes(this.key.toLowerCase()))))) &&
        (!this.filter || (!!this.filter && x.category === this.filter.pk))
      );
    });
  }

  catClass(filter: ECardCategoryResult) {
    return this.filter === filter ? 'selected' : '';
  }

  selectedClass(eCard: Image) {
    return this.eCard === eCard || this.eCard?.pk === eCard.pk
      ? 'selected'
      : '';
  }

  get disabled() {
    return !this.eCard;
  }

  get disabledClass() {
    return this.disabled ? 'disabled' : '';
  }

  continue() {
    if (!this.eCard) {
      return;
    }
    this.eCardChange.emit(this.eCard);
  }
}
