<div *ngFor="let i of items[0]" class="div--item placeholder-glow">
  <div class="d-flex justify-content-between align-items-center w-100">
    <div class="d-flex justify-content-center align-items-center flex-column w-30">
      <span class="receipt--number w-100 placeholder"></span>
      <span class="receipt--name w-100 placeholder"></span>
      <span class="receipt--desc w-100 placeholder"></span>
    </div>
    <div class="d-flex justify-content-center align-items-center flex-column w-30">
      <span class="receipt--name w-100 placeholder"></span>
      <span class="receipt--desc w-100 placeholder"></span>
    </div>
    <span class="receipt--img placeholder"></span>
  </div>
</div>