<input
  #uploader
  id="uploader"
  hidden
  type="file"
  accept="image/x-png,image/jpeg,application/pdf,.doc,.docx,.png,.jpeg,.jpg"
  (change)="fileChangeEvent($event)"
/>
<div [ngClass]="data?.dialogMode ? 'box--top' : ''">
  <div
    class="row g-0 box--content"
    [ngClass]="data?.dialogMode ? 'dialog-mode' : ''"
  >
    <div class="txt--title" [ngClass]="data?.dialogMode ? '' : 'display-none'">
      {{ 'common.addImage' | translate }}
    </div>
    <div class="col-auto d-flex flex-column gap-2" *ngIf="showImageCropper">
      <image-cropper
        class="box--img"
        [disabled]="true"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [aspectRatio]="4 / 3"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <a title="Flip Horizontal" (click)="flipHorizontal()">
            <img src="assets/img/icon-filp.svg" />
          </a>
          <a title="{{ 'common.leftRotate' | translate }}" (click)="rotateLeft()">
            <img src="assets/img/icon-rotate-left.svg" />
          </a>
          <a title="{{ 'common.rightRotate' | translate }}" (click)="rotateRight()">
            <img src="assets/img/icon-rotate-right.svg" />
          </a>
        </div>
        <div class="d-flex btn--action">
          <button
            type="button"
            class="btn btn-sm btn--img-action"
            (click)="saveCroppedImage()"
            [disabled]="saveBtnDisabled"
          >
            {{ 'common.cropImage' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-auto d-flex flex-column box--previews">
      <div class="error--msg">{{message}}</div>
      <div *ngFor="let item of uploadedFiles">
        <img
          class="box--preview"
          [src]="item.imgBase64"
          (click)="cropImage(item)"
        />
      </div>
      <button class="box--add" (click)="uploader.click()">+</button>
    </div>
  </div>
  <div *ngIf="data?.dialogMode" class="box--action">
    <div class="cancel--btn" [mat-dialog-close]>
      {{ 'common.back' | translate }}
    </div>
    <div
      *ngIf="!!uploadedFiles.length"
      class="btn--post"
      [ngClass]="disabledClass"
      [mat-dialog-close]="uploadedFiles"
      (click)="proceed()"
    >
      {{ 'common.proceed' | translate }}
    </div>
    <div *ngIf="!uploadedFiles.length" class="btn--post disabled">
      {{ 'common.proceed' | translate }}
    </div>
  </div>
  <div *ngIf="!data?.dialogMode" class="box--action">
    <div class="cancel--btn" (click)="goBack.emit()">
      {{ 'common.back' | translate }}
    </div>
    <div class="btn--post" [ngClass]="disabledClass" (click)="proceed()">
      {{ 'common.proceed' | translate }}
    </div>
  </div>
</div>
