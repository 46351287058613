import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { NotifComponent } from './notif.component';
import { ListComponent } from './list/list.component';

@NgModule({
  declarations: [NotifComponent, ListComponent],
  exports: [NotifComponent],
  imports: [CommonModule, RouterLink],
})
export class NotifModule {}
