import { Component, Input } from '@angular/core';
import Required from '../../utility/decorators/required.decorator';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-arrow-circle',
  templateUrl: './arrow-circle.component.html',
  styleUrls: ['./arrow-circle.component.scss'],
})
export class ArrowCircleComponent {
  @Input() @Required direction: 'up' | 'right' | 'down' | 'left' = 'up';
  @Input() @Required label = '';
  @Input() goTo: any[] | undefined;
  @Input() goToParams: any = {};
  @Input() disableClick = false;

  constructor(private router: Router, private location: Location, public lang: LangService) {}

  get icon() {
    const prefix = 'assets/img/';
    return prefix + this.direction + '-circle.png';
  }

  async onClick() {
    if (this.disableClick) {
      return;
    }
    if (!this.goTo) {
      // Go Back by one page
      this.location.back();
    } else {
      const queryParams: any = this.goToParams;
      await this.router.navigate(this.goTo, { queryParams });
    }
  }
}
