<div class="box--top">
  <div
    class="col-auto d-flex flex-column box--left"
    [ngClass]="item?.greeting_info?.type?.toLowerCase()"
  >
    <div class="txt--greeting">
      <ng-container
        *ngIf="item?.greeting_info?.type === GreetingType?.birthday"
      >
        {{ key | translate: { name: getFullName } }}
      </ng-container>
      <span
        *ngIf="item?.greeting_info?.type === GreetingType?.anniversary"
        [innerHTML]="
          key
            | translate
              : { name: getFullName, n: anniversaryDuration, o: ordinalSuffix }
        "
      ></span>
    </div>
    <div class="box--img">
      <img
        *ngIf="!!item?.greeting_info?.profile_pic"
        class="img--usr"
        [src]="img + item?.greeting_info?.profile_pic"
      />
      <ng-container *ngIf="!item?.greeting_info?.profile_pic">
        <div *ngIf="!!acronym" class="txt--acronym">{{ acronym }}</div>
        <img
          *ngIf="!acronym"
          class="img--usr"
          src="assets/img/default-user.png"
        />
      </ng-container>
      <img class="img--small" [src]="iconSmall" />
    </div>
    <div class="txt--name">{{ getFullName }}</div>
    <div class="txt--dept">{{ item?.greeting_info?.department_name }}</div>
  </div>
</div>
