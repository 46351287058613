import { Component, Input } from '@angular/core';
import Required from 'src/app/utility/decorators/required.decorator';
import Breadcrumb from '../../utility/model/breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() @Required segments: Breadcrumb[] = [];
  @Input() separator = '/';
  protected readonly Array = Array;
}
