import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-category-box',
  templateUrl: './sub-category-box.component.html',
  styleUrls: ['./sub-category-box.component.scss'],
})
export class SubCategoryBoxComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
