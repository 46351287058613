import components from './components';
import settings from './settings';
import servers from './servers';

const pwa = {
  components: components,
  settings: settings,
  servers: servers,
};

export { pwa };
