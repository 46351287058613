<div class="dialog-box text-center">
  <div class="message">
    {{ message }}
  </div>
  <div class="action-buttons mt-3">
    <div class="btn--confirm" (click)="onConfirmClick()">
      {{ confirmButtonText }}
    </div>
    <div class="btn--cancel" mat-dialog-close>{{ cancelButtonText }}</div>
  </div>
</div>
