import { pwa } from 'white-labelling/pwa';
import mergeDeep from '../merge-deep';

import components from './components';
import settings from './settings';
import servers from './servers';

const bous = {
  components: { ...pwa.components, ...components },
  settings: mergeDeep(pwa.settings, settings),
  servers: mergeDeep(pwa.servers, servers),
};

export { bous };
