import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ProfileComponent } from './profile.component';
import { PanelComponent } from './panel/panel.component';

@NgModule({
  declarations: [ProfileComponent, PanelComponent],
  exports: [ProfileComponent],
  imports: [
    CommonModule,
    RouterLink,
    TranslateModule.forChild({ extend: true }),
    NgClickOutsideDirective,
    MatTooltipModule,
  ],
})
export class ProfileModule {}
