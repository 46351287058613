<div *ngIf="cartDetails && cartItems" class="row cart-items">
  <div class="mt-3 cart-item" *ngFor="let item of cartItems">
    <div
      class="item-img cursor"
      [routerLink]="'/reward/' + item?.reward_details?.pk"
    >
      <img
        [src]="imgUrl + item?.reward_details?.thumbnail_img_url"
        class="mg-fluid cart--img"
      />
    </div>
    <div class="item-details">
      <p
        class="item-name cursor"
        [routerLink]="'/reward/' + item?.reward_details?.pk"
      >
        {{ item.reward_details.name }}
      </p>
      <div class="action-section">
        <div class="points-details">
          {{ item.item_points | number }} {{ "common.point.n" | translate }}
        </div>
        <div class="txt--qty" *ngIf="!editable">
          <span class="muted-text">{{ "common.quantity" | translate }}</span>
          {{ item?.quantity }}
        </div>

        <div class="box--slider" *ngIf="editable">
          <div class="delete-icon cursor" (click)="onRemoveConfirm(item)">
            <img src="/assets/img/delete.svg" />
          </div>
          <div
            class="box--sign"
            [ngClass]="{ active: item.quantity > 1 }"
            (click)="changeQty(item, SignE.minus)"
          >
            -
          </div>
          <div class="txt--qty">{{ item?.quantity }}</div>
          <div
            class="box--sign"
            [ngClass]="{ active: item.quantity < 5 }"
            (click)="changeQty(item, SignE.plus)"
          >
            +
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loader></app-loader>