import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent {
  @Input() key = '';
  @Input() placeholder = this.translate.instant('common.search');
  @Output() keyChange = new EventEmitter<string>();
  @Output() search = new EventEmitter();
  @Output() clear = new EventEmitter();

  constructor(private translate: TranslateService, public lang: LangService) {}

  onKeyChange(e: string) {
    this.keyChange.emit(e);
  }

  onEnter() {
    this.search.emit();
  }

  clearSearch() {
    this.key = '';
    this.clear.emit();
  }
}