<div *ngIf="!data?.uploadImageFromReceiptForm">
  <div class="upload--box">
    <img class="cross--icon" src="assets/img/cross.png" [mat-dialog-close]/>
    <div class="d-flex justify-content-center align-items-center flex-column mt-60">
      <img src="/assets/img/add-receipt.svg" alt="Add receipt icon"/>
      <div class="txt--title">{{ 'common.receipt.uploadReceipt' | translate }}</div>
    </div>
  </div>
  <div class="white--bg">
    <!-- Need to enable this when BE supports PDF for receipts -->
    <!-- <div class="upload--btn me-2" (click)="goToAddReceiptPage()" [mat-dialog-close]>
      <div class="icon d-flex justify-content-center align-items-center">
        <img src="/assets/img/file-icon.svg" alt="File icon"/>
      </div>
      <div class="d-flex flex-column align-items-start ms-2">
        <span class="primary--txt">Upload from Files</span>
        <span class="gray--txt">Use supported document file (pdf)</span>
      </div>
    </div> -->
    <div class="upload--btn" (click)="uploadImage()">
      <div class="icon d-flex justify-content-center align-items-center">
        <img src="/assets/img/gallery-icon.svg" alt="File icon"/>
      </div>
      <div class="d-flex flex-column align-items-start ms-2">
        <span class="primary--txt">{{ "common.receipt.uploadFromGallery" | translate }}</span>
        <span class="gray--txt">{{ "common.receipt.gallerySubTxt" | translate }}</span>
      </div>
    </div>
  </div>
</div>