import { Component } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-box-close',
  templateUrl: './box-close.component.html',
  styleUrls: ['./box-close.component.scss'],
})
export class BoxCloseComponent {
  constructor(public lang: LangService) {}
}
