import { Store } from '@ngrx/store';
import {
  AuthData,
  ExternalTokenAuth,
  TokenAuth,
} from 'src/app/utility/model/token-auth';
import { setAuthData } from './auth-data.actions';
import { UserProfile } from 'src/app/utility/model/user-profile';
import { DashboardData } from 'src/app/utility/model/dashboard-data';

export function updateTokenAuth(
  authDataStore: Store<{ authData: AuthData }>,
  authData: Partial<AuthData>,
  tokenAuth: TokenAuth,
  useNewTokenDate = false
) {
  authDataStore.dispatch(
    setAuthData({
      tokenDate: useNewTokenDate ? new Date() : authData?.tokenDate,
      tokenAuth: {
        ...authData?.tokenAuth,
        ...tokenAuth,
      },
      externalTokenAuth: {
        ...authData?.externalTokenAuth,
        token: tokenAuth?.token,
        email: tokenAuth?.user_details?.email,
        organization_id: tokenAuth?.user_details?.organization_pk,
        first_name: tokenAuth?.user_details?.first_name,
        last_name: tokenAuth?.user_details?.last_name,
      },
    })
  );
}

export function updateExternalTokenAuth(
  authDataStore: Store<{ authData: AuthData }>,
  authData: Partial<AuthData>,
  externalTokenAuth: ExternalTokenAuth,
  useNewTokenDate = false
) {
  authDataStore.dispatch(
    setAuthData({
      tokenDate: useNewTokenDate ? new Date() : authData?.tokenDate,
      externalLogin: true,
      externalTokenAuth: {
        ...authData?.externalTokenAuth,
        ...externalTokenAuth,
      },
      tokenAuth: {
        ...authData?.tokenAuth,
        token: externalTokenAuth?.token,
        user_details: {
          ...authData?.tokenAuth?.user_details,
          first_name: externalTokenAuth?.first_name,
          last_name: externalTokenAuth?.last_name,
          email: externalTokenAuth?.email,
          organization_pk: externalTokenAuth?.organization_id,
        },
      },
    })
  );
}

export function updateDashboard(
  authDataStore: Store<{ authData: AuthData }>,
  authData: Partial<AuthData>,
  dashboard: DashboardData
) {
  authDataStore.dispatch(
    setAuthData({
      dashboard: {
        ...authData?.dashboard,
        ...dashboard,
      },
      tokenAuth: {
        ...authData?.tokenAuth,
        user_details: {
          ...authData?.tokenAuth?.user_details,
          real_time_points: dashboard?.remaining_points,
          is_p2p_staff: dashboard?.is_p2p_staff,
          is_department_receipt_approver:
            dashboard?.is_department_receipt_approver,
        },
        organization_settings: {
          ...authData?.tokenAuth?.organization_settings,
          enable_referral_page: dashboard?.enable_referral_page,
          step_activity_limit: dashboard?.step_activity_limit,
          show_greetings: dashboard?.show_greetings,
          enable_news_feed: dashboard?.enable_news_feed ? 1 : 0,
        },
      },
    })
  );
}

export function updateRemainingPoint(
  authDataStore: Store<{ authData: AuthData }>,
  authData: Partial<AuthData>,
  remainingPoint: number
) {
  authDataStore.dispatch(
    setAuthData({
      tokenAuth: {
        ...authData?.tokenAuth,
        user_details: {
          ...authData?.tokenAuth?.user_details,
          real_time_points: remainingPoint!,
        },
      },
    })
  );
}

export function updateProfInfo(
  authDataStore: Store<{ authData: AuthData }>,
  authData: Partial<AuthData>,
  userDetail: UserProfile
) {
  authDataStore.dispatch(
    setAuthData({
      tokenAuth: {
        ...authData?.tokenAuth,
        organization_name: userDetail.organization_name,
        user_details: {
          ...authData?.tokenAuth?.user_details,
          ...userDetail,
        },
      },
      externalTokenAuth: {
        ...authData?.externalTokenAuth,
        first_name: userDetail?.first_name,
        last_name: userDetail?.last_name,
        organization_id: userDetail?.organization_pk,
        email: userDetail?.email,
      },
    })
  );
}

export function updateProfPic(
  authDataStore: Store<{ authData: AuthData }>,
  authData: Partial<AuthData>,
  profPic: string
) {
  authDataStore.dispatch(
    setAuthData({
      tokenAuth: {
        ...authData?.tokenAuth,
        user_details: {
          ...authData?.tokenAuth?.user_details,
          profile_pic_url: profPic,
        },
      },
    })
  );
}

export function updateEmail(
  authDataStore: Store<{ authData: AuthData }>,
  authData: Partial<AuthData>,
  email: string
) {
  authDataStore.dispatch(
    setAuthData({
      externalTokenAuth: {
        ...authData?.externalTokenAuth,
        email,
      },
      tokenAuth: {
        ...authData?.tokenAuth,
        user_details: {
          ...authData?.tokenAuth?.user_details,
          email,
        },
      },
    })
  );
}

export function updateOrganizationName(
  authDataStore: Store<{ authData: AuthData }>,
  authData: Partial<AuthData>,
  name: string
) {
  authDataStore.dispatch(
    setAuthData({
      tokenAuth: {
        ...authData?.tokenAuth,
        organization_name: name,
        user_details: {
          ...authData?.tokenAuth?.user_details,
          organization_name: name,
        },
      },
    })
  );
}
