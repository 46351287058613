import { Component } from '@angular/core';

@Component({
  selector: 'app-receipt-list-loader',
  templateUrl: './receipt-list-loader.component.html',
  styleUrls: ['./receipt-list-loader.component.scss']
})
export class ReceiptListLoaderComponent {
  items = [Array(5)];
}
