<div class="box--top">
  <div class="d-flex flex-row-reverse box--close-contain">
    <div
      class="box--close"
      [class]="lang.isRTL ? 'rtl--close' : ''"
      [mat-dialog-close]
      (click)="closeModal()"
    >
      <i class="bi bi-x"></i>
    </div>
  </div>
  <div class="notification--greeting" [ngClass]="greetingType">
    <div class="greeting--title">
      {{ greetingTitle }}
    </div>

    <div class="content-box" *ngIf="!isSender">
      <div class="txt--greeting">
        <ng-container
          *ngIf="data.feedItem?.greeting_info?.type === GreetingType?.birthday"
        >
          {{ key | translate: { name: getFullName } }}
        </ng-container>
        <span
          *ngIf="
            data.feedItem?.greeting_info?.type === GreetingType?.anniversary
          "
          [innerHTML]="
            key
              | translate
                : {
                    name: getFullName,
                    n: anniversaryDuration,
                    o: ordinalSuffix
                  }
          "
        ></span>
      </div>
      <div class="box--img">
        <img
          *ngIf="!!data?.item?.recipient_data?.profile_pic_url"
          class="img--usr"
          [src]="img + data?.item?.recipient_data?.profile_pic_url"
        />
        <ng-container *ngIf="!data?.item?.recipient_data?.profile_pic_url">
          <div *ngIf="!!acronym" class="txt--acronym">{{ acronym }}</div>
          <img
            *ngIf="!acronym"
            class="img--usr"
            src="assets/img/default-user.png"
          />
        </ng-container>
        <img class="img--small" [src]="iconSmall" />
      </div>
      <div class="txt--name">{{ getFullName }}</div>
      <div class="txt--date" [ngClass]="greetingType">
        <span>{{
          data?.feedItem?.greeting_info?.date | date: "MMM dd, yyyy"
        }}</span>
      </div>

      <div class="points-section" *ngIf="data.item?.points != 0">
        <img src="./assets/img/stars-gold.png" />
        <div class="points-message">
          {{ "msg.point.youHaveReceived" | translate }}
        </div>
        <div class="points">
          {{ data.item?.points }} {{ "common.point.n" | translate }}
        </div>
      </div>
      <div class="description-section mt-4">
        {{ data.feedItem?.description }}
        <div class="image-section mt-3" *ngIf="getImage">
          <img [src]="getImage" />
        </div>
      </div>
      <div class="btn btn-primary view-post-btn mt-3" (click)="redirect()">
        View Post
      </div>
    </div>

    <div class="content-box" *ngIf="isSender">
      <div class="d-flex flex-column box--link">
        <img src="assets/img/appreciate-link.png" />
        <div class="img--love">
          <img [src]="iconSmall" class="icon" />
        </div>
        <img
          *ngIf="!!getProfile1"
          class="img--person left"
          [src]="getProfile1"
        />
        <div *ngIf="!getProfile1" class="txt--acronym-small left">
          {{ acronym1 }}
        </div>
        <ng-container>
          <img
            *ngIf="!!getProfile"
            class="img--person right"
            [src]="getProfile"
          />
          <div *ngIf="!getProfile" class="txt--acronym-small img--person right">
            {{ acronym }}
          </div>
        </ng-container>
      </div>

      <div class="greeting-message mt-4">
        <span class="fw-bold">{{ getFullName1 }}</span> has wish you a
        {{ greetingTitle }}
      </div>

      <div class="text-muted mt-2">
        {{ data.item?.date }}
      </div>

      <div class="description-section mt-4">
        {{ data.feedItem?.description }}
        <div class="image-section mt-3" *ngIf="getImage">
          <img [src]="getImage" />
        </div>
      </div>
    </div>
  </div>
</div>
