import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthData } from 'src/app/utility/model/token-auth';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import Breadcrumb from 'src/app/utility/model/breadcrumb';
import { enabledModuleCounts } from 'src/app/utility/common-logic';
import { showError } from 'src/app/utility/common-logic';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LanguageMode } from 'src/app/utility/enum';
import { Cart } from 'src/app/utility/model/cart';

@Component({
  selector: 'app-header',
  templateUrl: environment?.components?.header?.templateUrl,
  styleUrls: environment?.components?.header?.styleUrls,
})
export class HeaderComponent implements OnInit {
  authData: Partial<AuthData> = {};
  languageAvailable = environment.settings.languageAvailable;
  imgUrl = environment.url.img;
  @Input() sticky = '';
  LanguageMode = LanguageMode;
  @Output() toggleDrawer = new EventEmitter<'left' | 'right'>();
  cartDetails: any;
  cartItems: Cart[];
  cartCount: number;
  cartStatus: string;
  header = environment.settings.header;
  applyBgColorFromDjango = environment.settings.applyBgColorFromDjango;
  @Input() segments: Breadcrumb[] = [];
  headerLogoMaxHeight = environment.settings.headerLogoMaxHeight;
  modules = environment.settings.modules;
  topMenu = environment.settings.topMenu;
  feeds = environment.settings.feeds;
  leftNavigate = environment.settings.leftNavigate;
  showProfile = environment.settings.header.showProfile;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private router: Router,
    private api: ApiService,
    private shared: SharedService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
    if (this.modules.cart) {
      this.shared.cartCount.subscribe((count) => {
        this.cartCount = count;
      });
      this.shared.cartDetails.subscribe((res) => {
        this.cartDetails = res;
      });
      this.shared.cartStatus.subscribe((status) => {
        this.cartStatus = status;
      });
      // This should be only to Cart Details API, everywhere else subscribe to the data
      this.getCartDetails();
    }
  }

  get enabledModuleCounts() {
    return enabledModuleCounts(this.modules);
  }

  get isLoginTnc() {
    return this.router.url === '/login-tnc';
  }

  get addHamburgerMenu() {
    return (
      this.router.url === '/home' ||
      this.router.url === '/' + this.feeds.path + '/appreciation' ||
      this.router.url === '/' + this.feeds.path + '/post-n-polls' ||
      this.router.url === '/rewards' ||
      this.router.url === '/rewards/browse-all' ||
      this.router.url === '/rewards/my-wallet' ||
      this.router.url === '/calendar' ||
      this.router.url === '/calendar/all' ||
      this.router.url === '/calendar/announcement' ||
      this.router.url === '/calendar/event' ||
      this.router.url === '/calendar/greeting' ||
      this.router.url === '/leaderboard' ||
      this.router.url === '/leaderboard/recognition' ||
      this.router.url === '/receipt-list' ||
      this.router.url === '/receipt-list/all' ||
      this.router.url === '/receipt-list/processing' ||
      this.router.url === '/receipt-list/approved' ||
      this.router.url === '/receipt-list/rejected' ||
      this.router.url === '/sales-receipt-list/all' ||
      this.router.url === '/sales-receipt-list/processing' ||
      this.router.url === '/sales-receipt-list/approved' ||
      this.router.url === '/sales-receipt-list/rejected'
    );
  }

  get bgClass() {
    return this.applyBgColorFromDjango ? 'dynamic' : '';
  }

  get stickyClass() {
    return this.sticky ? 'sticky' : '';
  }

  get logo2() {
    if (
      !this.authData?.tokenAuth?.organization_details?.logo ||
      this.authData?.tokenAuth?.organization_details?.logo === this.imgUrl + '/'
    ) {
      return '';
    }
    return this.authData?.tokenAuth?.organization_details?.logo.startsWith(
      this.imgUrl,
    )
      ? this.authData?.tokenAuth?.organization_details?.logo
      : this.imgUrl + this.authData?.tokenAuth?.organization_details?.logo;
  }

  get logoStyle() {
    return { 'max-height': this.headerLogoMaxHeight };
  }

  get showAppBackButton() {
    return this.authData?.tokenAuth?.organization_details?.slug ===
      'toa-paints-api'
      ? true
      : false;
  }

  async getCartDetails() {
    await this.api
      .getCartDetails()
      .then((res) => {
        if (res) {
          this.cartDetails = res;
          this.cartCount = res?.count;
          this.cartItems = res?.results;
          this.shared.cartCount.next(res?.count);
          this.shared.cartDetails.next(this.cartDetails);
          this.shared.cartItems.next(res?.results);
          this.shared.cartStatus.next(res?.status);
          if (res?.status === 'In process') {
            setTimeout(() => {
              this.getCartDetails();
            }, 4000);
          }
        }
      })
      .catch((err) => showError(err, this.toastr, this.translate));
  }

  backTopApp() {
    if (this.shared.isMobile()) {
      window.location.href = 'toapainter://rdsdms.com';
    }
  }
}
