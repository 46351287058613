<div class="img--container">
  <img class="cross--icon" src="assets/img/cross.png" [mat-dialog-close] />
  <img class="w-100" [src]="userPhoto(eventImage)" />
  <div class="mt-2" *ngIf="eventImage?.caption"><b> {{ "common.title" | translate }}: </b> {{ eventImage.caption }}
  </div>
  <div class="d-flex justify-content-between align-items-center mt-3">
    <div class="d-flex align-items-center">
      <div>
        <img *ngIf="eventImage?.owner?.user_img" class="img--profile" [src]="imgUrl + eventImage.owner.user_img" />
        <ng-container *ngIf="!eventImage?.owner?.user_img">
          <div *ngIf="!!acronym()" class="col-auto txt--acronym">
            {{ acronym() }}
          </div>
          <img *ngIf="!acronym()" class="img--profile" src="assets/img/default-user.png" />
        </ng-container>
      </div>
      <div class="ms-2" *ngIf="eventImage?.owner?.full_name">
        <div class="by--txt">{{ "common.by" | translate: { n: '' } }}</div>
        <div class="user--name">{{ eventImage?.owner?.full_name }}</div>
      </div>
    </div>
    <div
      *ngIf="((authData?.tokenAuth?.user_details?.pk === this.eventImage?.owner?.pk) || authData?.tokenAuth?.user_details?.is_staff) && eventImage?.can_edit"
      class="d-flex">
      <button class="btn edit--btn" (click)="showEditForm = true" [disabled]="showEditForm">{{ "common.edit" | translate
        }}</button>
      <button class="btn delete--btn ms-2" (click)="deleteImage()" [disabled]="deletingImage">{{ "common.delete" |
        translate }}</button>
    </div>
  </div>

  <div class="mt-3" *ngIf="showEditForm">
    <div class="mb-2 user--name">{{ "common.editCaption" | translate }}</div>
    <input class="form-control" type="text" [(ngModel)]="imageCaption"
      [placeholder]="'common.enterCaption' | translate" />
    <div class="d-flex justify-content-end mt-3">
      <button class="btn edit--btn" (click)="showEditForm = false">{{ "common.no" | translate }}</button>
      <button class="btn delete--btn ms-2" (click)="updateCaption()" [disabled]="updatingCaption">{{ "common.yes" |
        translate }}</button>
    </div>
  </div>
</div>