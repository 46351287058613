import { environment } from "src/environments/environment";

const apiEndpoint = environment.url.app;

const byPassedApis = [
  'profiles/api/token-auth',
  'profiles/api/external-token-auth',
  'profiles/api/users/reset_password/',
  'profiles/activate-user/',
  'profiles/api/organizations/?sub_domain=',
  'profiles/api/users/resend_anonymous_user_otp/',
  'profiles/api/register/',
  'profiles/api/confirm-registration',
  'profiles/api/twofa_confirm',
  'profiles/api/twofa_resend',
  'profiles/api/send_otp_to_user/',
  'profiles/api/verify_anonymous_otp/',
  'profiles/api/product_choices',
  'profiles/api/departments/?org_slug=',
  'profiles/api/verify_otp/'
]

export const bypassedApisWithoutEndpoint = [...byPassedApis];

export const bypassedApisWithEndpoint = byPassedApis.map(api => apiEndpoint + api);
