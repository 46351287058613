import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthData } from '../utility/model/token-auth';
import { environment } from '../../environments/environment';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

declare var PushNotification: any;

@Injectable({
  providedIn: 'root',
})
export class PushNotifyService {
  // api_path = 'https://tom.skordev.com/';
  api_path: any = environment.url.app;
  basepath: any = environment.url.basePath;

  authData: Partial<AuthData> = {};
  sub: PushSubscription | undefined;
  fcm_token: any;
  post_data: any;

  readonly VAPID_PUBLIC_KEY =
    'BPoMkoxfW6vCF1q-lpkSGMyNdAPSW0r7jreRWgzeR5pP_XcJ5aZsa-x32AjfXmKysOjjBXNouJF4RZzrUT44Cls';

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private http: HttpClient,
    private router: Router,
    private swPush: SwPush,
    private swUpdate: SwUpdate
  ) {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
  }

  addPushSubscriber(subc: any) {
    const header = new HttpHeaders({ Authorization: 'Token ' + this.authData?.tokenAuth?.token });
    return this.http.post(
      this.api_path + 'push_notifications/api/web_push_subscription/',
      subc,
      { headers: header }
    );
  }

  subscribeToNotifications() {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY,
      })
      .then((sub) => {
        this.sub = sub;
        this.fcm_token = sub;
        this.addPushSubscriber(sub).subscribe({
          next: () => console.log('Sent push subscription object to server.'),
          error: (err) => console.error('Could not send subscription object to server, reason: ', err),
        });
      })
      .catch((err) =>
        console.error('Could not subscribe to notifications', err)
      );
  }

  showMessages() {
    this.swPush.messages.subscribe((message) => {
      this.swPush.notificationClicks.subscribe(({ action, notification }) => {
        // if(notification.data.redirect_to == 'appreciate'){
        window.location.href = this.basepath + 'appreciate-landing/heros';
        // }else if(notification.data.redirect_to == 'rewardz'){
        // window.location.href = this.base_path + 'appreciate-landing/heros';
        // }
        // window.open(notification.data.url);
        // window.open('https://www.google.com/', '_blank');
        // window.location.href('https://www.google.com/', '_blank');
        // this.router.navigate(['notification']);
      });
      // let notification = message['notification']
    });
  }
}
