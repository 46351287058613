<mat-select *ngIf="mode === LanguageMode.textDropdown" class="form-control mode1" [(ngModel)]="langS.lang">
  <mat-option *ngFor="let l of langS.langsAvailable" value="{{ l }}">
    {{ langS.langLabel(l) }}
  </mat-option>
</mat-select>
<i *ngIf="mode === LanguageMode.switch && !isIconSVG" class="bi bi-globe2" [ngClass]="btnClass" (click)="switch()"></i>
<img *ngIf="mode === LanguageMode.switch && isIconSVG" src="assets/img/global.svg" class="cursor-pointer lang-icon 1"
  (click)="switch()" />
<div *ngIf="mode === LanguageMode.iconDropdown" class="dropdown">
  <i *ngIf="!isIconSVG" class="bi bi-globe2" [ngClass]="btnClass" (click)="toggleStyleDisplay(style)"
    (clickOutside)="style.display = 'none'"></i>
  <img src="assets/img/global.svg" class="cursor-pointer lang-icon 2" *ngIf="isIconSVG"
    (click)="toggleStyleDisplay(style)" />
  <div class="dropdown-content" [ngClass]="langS.langsAvailable.length > 12 ? 'dropdown-scroll' : ''"
    [class]="langS.isRTL ? 'rtl-dropdown-content' : ''" [ngStyle]="style">
    <mat-list>
      <mat-list-item *ngFor="let l of langS.langsAvailable" (click)="langS.lang = l" class="list-item"
        [ngClass]="selectedClass(l, 'list-item-')">
        <div class="txt--label" [ngClass]="selectedClass(l)">
          <span>
            {{ langS.langLabel(l) }}
          </span>
          <i *ngIf="langS.lang === l" class="bi bi-check-lg selected"></i>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>