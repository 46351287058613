<div class="box--top">
  <a class="text1" href="https://abundantly.freshdesk.com/support/tickets/new" target="_blank">
    {{ 'common.contactUs' | translate }}
  </a>
  <table style="width: 300px">
    <tr>
      <td class="text2">{{ 'common.supportNumber' | translate }}</td>
      <td class="text2"><strong>888 522 2207</strong></td>
    </tr>
  </table>
</div>
