import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SearchBoxComponent } from './search-box/search-box.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryBoxComponent } from './sub-category-box/sub-category-box.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SearchBoxComponent,
    CategoryComponent,
    SubCategoryBoxComponent,
    SubCategoryComponent,
  ],
  exports: [
    SearchBoxComponent,
    CategoryComponent,
    SubCategoryBoxComponent,
    SubCategoryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild({ extend: true }),
  ],
})
export class InputModule {}
