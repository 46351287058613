import { Component } from '@angular/core';

@Component({
  selector: 'app-receipt-form-loader',
  templateUrl: './receipt-form-loader.component.html',
  styleUrls: ['./receipt-form-loader.component.scss']
})
export class ReceiptFormLoaderComponent {
  items = [Array(5)];
}
