import { createReducer, on } from '@ngrx/store';
import { setAuthData } from './auth-data.actions';
import { AuthData, TokenAuth } from 'src/app/utility/model/token-auth';
import { environment } from 'src/environments/environment';

export function initTokenAuth(): TokenAuth {
  return {
    organization_name: '',
    has_point_categories: false,
    organization_details: {
      logo: '',
      background_color: '',
      slug: '',
      emergency_services_available: false,
      country: '',
    },
    user_details: {
      pk: undefined,
      email: '',
      phone_number: '',
      organization_name: '',
      organization_pk: undefined,
      department_name: '',
      using_default_password: false,
      profile_pic_url: '',
      official_title: '',
      real_title: '',
      first_name: '',
      last_name: '',
      office_phone_number: '',
      address: '',
      address2: '',
      martial_status: '',
      date_of_birth: '',
      wedding_date: '',
      is_staff: false,
      mantra: '',
      is_profile_public: false,
      strengths: [],
      rank: 0,
      delivery_address: {
        name: '',
        address: '',
        address2: '',
        contact_number: '',
        pincode: '',
        country: '',
      },
      is_dob_public: false,
      is_anniversary_public: false,
      departments_list: [],
      groups_list: [],
      departments_ids: [],
      groups_ids: [],
      img: null,
      employee_id: '',
      is_p2p_staff: false,
      appreciation_left: null,
      current_tier: '',
      hide_appreciation: false,
      is_marketing_staff: false,
      termination_date: null,
      date_of_joining: '',
      age: 0,
      is_send_low_balance: false,
      is_send_affilated_org_low_balance: false,
      is_admin_staff: false,
      benefit_grade: null,
      nationality: '',
      id_type: '',
      id_number: '',
      base_salary: null,
      is_department_receipt_approver: false,
      no_of_dependents: 0,
      is_point_allocation_approver: false,
      user_nomination_badges: [],
      received_count: 0,
      given_count: 0,
      post_poll_count: 0,
      real_time_points: 0,
      appreciation_ratio: null,
      checked: false,
      line_manager: {
        pk: undefined,
        first_name: '',
        last_name: '',
        profile_pic_url: '',
      },
      departments: [],
      profile_img: '',
      full_name: '',
      tax_percentage: '',
      is_line_manager: false,
    },
    hide_leaderboard: false,
    connected_tracker: null,
    cerra_rewards: false,
    require_twofa: false,
    currency: environment.settings.defaultCurrency,
    token: '',
    require_email_twofa: false,
    organization_settings: {
      enable_referral_page: 0,
      step_activity_limit: 0,
      enable_facility_checkin: 0,
      enable_activity_tracker: 0,
      hide_whats_on: 0,
      show_greetings: 0,
      multi_org_post: 0,
      enable_news_feed: 0,
      p2p_points_limit: 0,
      enable_feedback: 0,
      enable_nominations: 0,
      enable_biometrics: 0,
      enable_receipts_upload: 0,
      enable_analytics: 0,
      enable_rewards: 0,
      enable_gamification: 0,
      show_departments_for_system_created_greetings: 0,
      enable_benefit_receipts_upload: 0,
    },
    date_for_point_credit: null,
    layer_app_id: null,
    has_discount_categories: false,
    terms_and_conditions: '',
    title: '',
    status: null,
  };
}

function initDefaultAuthData(): AuthData {
  return {
    tokenAuth: initTokenAuth(),
    externalTokenAuth: {
      first_name: '',
      last_name: '',
      organization_id: undefined,
      token: '',
      email: '',
      detail: '',
      employee_id: '',
    },
    dashboard: {
      announcements: [],
      average_steps: 0,
      badge_counter: 0,
      daily_steps: 0,
      events: [],
      greetings: {
        event_birthday: [],
        event_anniversary: [],
      },
      step_tracker: '',
      step_activity_limit: 0,
      show_greetings: 0,
      show_quicklinks: 0,
      show_events: 0,
      featured_rewards: [],
      top_rewards: [],
      redeemed_points: 0,
      remaining_points: 0,
      referral_count: 0,
      total_points: 0,
      points_expiring_this_month: 0,
      points_since_tier_reset: 0,
      enable_referral_page: 0,
      allow_user_post_feed: false,
      enable_news_feed: false,
      hide_leaderboard: false,
      hide_team_leaderboard: false,
      enable_tier: false,
      is_p2p_staff: false,
      is_user_hod: false,
      is_prod_user: false,
      campaigns: [],
      rewards_points_multiplier: 0,
      e_cash_multiplier: 0,
      days_till_password_expire: 0,
      prompt_password_expire: false,
      reward_tier_reset_date: {},
      current_tier: 0,
      tier_infos: [],
      atsn_data: {},
      next_expiry_date: '',
      secondary_points: 0,
      enable_department_level_receipt_approval: false,
      is_department_receipt_approver: false,
      number_of_receipt_approval_required: 0,
      is_decimal_support_in_transaction_enabled: false,
      disable_file_upload_in_ui: false,
      contact_details: null,
      chat_group: null,
      capacity: 0,
      flexi_benefits_url: '',
    },
    timestamp: '',
    externalLogin: false,
    tokenDate: new Date(),
    appName: environment.settings.appName,
    subDomain: '',
    uuid: '',
    cred: {
      username: '',
      password: '',
    },
    notifBadgeCounter: 0,
    approvalsCounter: 0,
    loading: false,
  };
}

export const authDataReducer = createReducer(
  initDefaultAuthData(),
  on(setAuthData, (state: AuthData, payload: Partial<AuthData>) => ({
    ...state,
    ...payload,
  })),
);
