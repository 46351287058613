import { createReducer, on } from '@ngrx/store';
import { UsersActions, UsersApiActions } from './users.actions';
import { UserProfile } from 'src/app/utility/model/user-profile';

const userCollectionInitialState: Array<UserProfile> = [];

export const userCollectionReducer = createReducer(
  userCollectionInitialState,
  on(
    UsersActions.add,
    (state: Array<UserProfile>, { usr }: { usr: UserProfile }) => {
      if (state.findIndex((x: UserProfile) => x.pk === usr.pk) > -1) {
        return state;
      }
      return [...state, usr];
    }
  ),
  on(
    UsersActions.remove,
    (state: Array<UserProfile>, { usr }: { usr: UserProfile }) =>
      state.filter((x: UserProfile) => x.pk !== usr.pk)
  ),
  on(UsersActions.clear, () => [])
);

const usersInitialState: ReadonlyArray<UserProfile> = [];

export const usersReducer = createReducer(
  usersInitialState,
  on(
    UsersApiActions.retrievedList,
    (
      _state: ReadonlyArray<UserProfile>,
      { users }: { users: ReadonlyArray<UserProfile> }
    ) => users
  )
);
