<div [ngClass]="data?.dialogMode ? 'box--top' : ''">
  <div class="txt--title">
    {{ "common.addECard" | translate }}
  </div>
  <div class="box--search">
    <app-search-box [(key)]="key"></app-search-box>
  </div>
  <div class="row g-0 btn--cats">
    <div
      *ngFor="let cat of eCardCats"
      class="col-auto btn--cat"
      [ngClass]="catClass(cat)"
      (click)="selectFilter(cat)"
    >
      {{ cat.name }}
    </div>
  </div>
  <div class="row g-0 box--ecards">
    <div
      *ngFor="let img of eCards2"
      class="col-auto box--ecard"
      [ngClass]="selectedClass(img)"
    >
      <div class="div--select-mark" [ngClass]="selectedClass(img)">✓</div>
      <img
        class="img-fluid img--ecard"
        [ngClass]="selectedClass(img)"
        [src]="img.image"
        (click)="eCard = img"
      />
    </div>
  </div>
  <app-progress-bar
    *ngIf="loading"
    class="mt-3 progress-bar"
  ></app-progress-bar>
  <div *ngIf="data?.dialogMode" class="box--action">
    <div class="cancel--btn" [mat-dialog-close] (click)="onGoBack()">
      {{ "common.back" | translate }}
    </div>
    <div *ngIf="!eCard" class="btn--post disabled">
      {{ "common.proceed" | translate }}
    </div>
    <div
      *ngIf="!!eCard"
      class="btn--post"
      [ngClass]="disabledClass"
      [mat-dialog-close]="eCard"
      (click)="continue()"
    >
      {{ "common.proceed" | translate }}
    </div>
  </div>
  <div *ngIf="!data?.dialogMode" class="box--action">
    <div class="cancel--btn" (click)="onGoBack()">
      {{ "common.back" | translate }}
    </div>
    <div class="btn--post" (click)="continue()" [ngClass]="disabledClass">
      {{ "common.proceed" | translate }}
    </div>
  </div>
</div>
