import { Component, Input } from '@angular/core';
import { Notification } from '../../../utility/model/notification-list';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Input() notifications?: Notification[] | null;
}
