import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { environment } from '../../../environments/environment';
import { LangService } from '../../services/lang.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  dialogMode: boolean;
  gif?: IGif;
}

@Component({
  selector: 'app-add-gif',
  templateUrl: './add-gif.component.html',
  styleUrls: ['./add-gif.component.scss'],
})
export class AddGifComponent implements OnInit {
  @Input() isAddPostPoll = '';
  @Input() isOpen = false;
  @Output() goBack = new EventEmitter();
  gf = new GiphyFetch(environment.settings.giphy.apiKey);
  gifs: IGif[] = [];
  key = '';
  gif: IGif | undefined;
  prevGif: IGif | undefined;
  @Output() gifChange = new EventEmitter<IGif>();
  loading = false;

  constructor(
    private lang: LangService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  async ngOnInit() {
    if (!!this.data?.gif) {
      this.gif = this.data?.gif;
    }
    this.prevGif = this.gif;
    await this.getImages();
  }

  onGoBack() {
    this.gif = this.prevGif;
    this.goBack.emit();
  }

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler() {
    if ((this.isAddPostPoll && this.isOpen) || !this.isAddPostPoll) {
      if (this.gif?.id === this.prevGif?.id) {
        this.goBack.emit();
      } else {
        this.gif = this.prevGif;
      }
    }
  }

  async getImages(term: string = 'appreciate') {
    try {
      this.loading = true;
      const gifResult = await this.gf.search(term, {
        sort: 'relevant',
        lang: this.lang.lang,
        limit: 150,
      });
      this.gifs = gifResult.data;
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  async searchImages() {
    if (this.key.length > 2) {
      await this.getImages(this.key);
    } else {
      await this.getImages();
    }
  }

  selectedClass(gif: IGif) {
    return this.gif?.id === gif?.id ? 'selected' : '';
  }

  get disabled() {
    return !this.gif;
  }

  get disabledClass() {
    return this.disabled ? 'disabled' : '';
  }

  continue() {
    if (!this.gif) {
      return;
    }
    this.gifChange.emit(this.gif);
  }
}
