import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthData } from 'src/app/utility/model/token-auth';
import { NotificationList } from '../../utility/model/notification-list';
import { ApiService } from '../../services/api-services/api.service';
import { showError, toggleStyleDisplay } from 'src/app/utility/common-logic';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { setAuthData } from 'src/app/state/auth-data/auth-data.actions';

@Component({
  selector: 'app-notif',
  templateUrl: './notif.component.html',
  styleUrls: ['./notif.component.scss'],
})
export class NotifComponent implements OnInit {
  authData: Partial<AuthData> = {};
  @Input() mode: 'page' | 'dropdown' = 'page';
  notif?: NotificationList;
  toggleStyleDisplay = toggleStyleDisplay;
  style: Partial<CSSStyleDeclaration> = { display: 'none' };

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private api: ApiService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
    await this.getNotifications();
  }

  async getNotifications() {
    await this.api
      .getNotifications()
      .then((res) => {
        this.notif = res;
        this.authDataStore?.dispatch(
          setAuthData({ notifBadgeCounter: res?.badge_counter })
        );
      })
      .catch((err) => showError(err, this.toastr, this.translate));
  }
}
