export default {
  dev: {
    admin: 'https://skordev.com/admin-dashboard/#/report',
    app: 'https://skordev.com/',
    basePath: 'https://pwa.skordev.com/#/',
    img: 'https://skordev.com',
    SSOUrl: '',
    sentryReplay: '',
  },
  prod: {
    admin: 'https://cerrapoints.com/admin-dashboard/#/report',
    app: 'https://cerrapoints.com/',
    basePath: 'https://pwa.cerrapoints.com/#/',
    img: 'https://cerrapoints.com',
    SSOUrl: '',
    sentryReplay: '',
  },
};
