import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ImgFile } from '../../utility/model/images';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DeleteConfirmationComponent } from 'src/app/redeem/modal/delete-confirmation/delete-confirmation.component';

interface DialogData {
  dialogMode: boolean;
  images?: ImgFile[];
  only1Image?: boolean;
  addCaption?: boolean;
}

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss'],
})
export class AddImageComponent implements OnInit {
  @Input() isAddPostPoll = '';
  @Input() isOpen = false;
  @Output() goBack = new EventEmitter();
  imageChangedEvent: any = '';
  uploadedFiles: ImgFile[] = [];
  croppedImage = '';
  currentProcessingImg = 0;
  canvasRotation = 0;
  transform: ImageTransform = {};
  @Output() imagesChange = new EventEmitter<ImgFile[]>();
  imageTitle: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<AddImageComponent>,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    (document.getElementById('uploader') as HTMLElement).click();
  }

  get disabled() {
    return !this.uploadedFiles.length;
  }

  get disabledClass() {
    return this.disabled ? 'disabled' : '';
  }

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler() {
    if ((this.isAddPostPoll && this.isOpen) || !this.isAddPostPoll) {
      if (!!this.croppedImage) {
        this.croppedImage = '';
        this.currentProcessingImg = 0;
        this.imageChangedEvent = '';
      } else if (!!this.uploadedFiles.length) {
        this.uploadedFiles.pop();
      } else {
        this.goBack.emit();
        if (this.data?.dialogMode) {
          this.dialogRef.close();
        }
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {}

  cropperReady() {}

  loadImageFailed() {}

  cropImage(img: ImgFile) {
    this.croppedImage = img.imgBase64;
    this.currentProcessingImg = img.imgNo;
    const imgObj = this.uploadedFiles.find((x) => x.imgNo === img.imgNo);
    this.imageChangedEvent = { target: { files: [imgObj.imgFile] } };
  }

  fileChangeEvent(event: any): void {
    const uploadedFilesCount = this.uploadedFiles.length;
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.uploadedFiles.push({
          imgNo: i + uploadedFilesCount + 1,
          imgBase64: String(reader.result),
          imgFile: file,
          type: 'image'
        });
        this.cropImage(this.uploadedFiles[0]);
      };
    }
  }

  saveCroppedImage() {
    const imgObj = this.uploadedFiles.find(
      (x) => x.imgNo === this.currentProcessingImg,
    );
    imgObj.imgBase64 = this.croppedImage;
    this.imageChangedEvent = null;
    const nextImgObj = this.uploadedFiles.find(
      (x) => x.imgNo === this.currentProcessingImg + 1,
    );
    if (nextImgObj != undefined) {
      this.cropImage(nextImgObj);
    } else {
      this.cropImage(imgObj);
    }
  }

  resetImage() {
    this.canvasRotation = 0;
    this.transform = {};
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }

  async proceed() {
    if (!this.uploadedFiles.length) {
      return;
    }
    if (this.data?.only1Image && this.data?.addCaption) {
      if (!this.imageTitle) {
        const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
          data: {
            message: this.translate.instant('msg.item.addWithoutTitle'),
            buttonText: {
              ok: this.translate.instant('common.yes'),
              cancel: this.translate.instant('common.no'),
            },
          },
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.dialogRef.close(this.uploadedFiles);
          }
        });
      } else if (this.imageTitle) {
        let imageTitle = this.imageTitle;
        let images = this.uploadedFiles;
        this.dialogRef.close({ imageTitle, images });
      }
    } else {
      this.dialogRef.close(this.uploadedFiles);
      for (let i = 0; i < this.uploadedFiles.length; i++) {
        let item = this.dataURItoBlob(this.uploadedFiles[i].imgBase64);
        const file = new File([item], this.uploadedFiles[i].imgFile.name, {
          type: 'image/png',
        });
        this.uploadedFiles[i].imgFile = file;
      }
      this.imagesChange.emit(this.uploadedFiles);
    }
  }
}
