<div class="user-list-left-box">
  <div class="thank-container">
    <h2 class="txt--title">{{ "msg.hello.cerraApplause" | translate }}</h2>
    <ul [class]="lang.isRTL ? 'list-item-rtl' : ''">
      <li>
        <strong>{{ "common.spreadTheCheer" | translate }}</strong
        ><br /><span [innerHTML]="'msg.spreadTheCheer' | translate"></span>
      </li>
      <li>
        <strong>{{ "common.stayConnected" | translate }}</strong
        ><br /><span [innerHTML]="'msg.stayConnected' | translate"></span>
      </li>
      <li>
        <strong>{{ "common.redeemYourPoints" | translate }}</strong
        ><br /><span [innerHTML]="'msg.redeemYourPoints' | translate"></span>
      </li>
    </ul>
    <div class="cloud-bg">
      <div class="bottom-icons" [class]="lang.isRTL ? 'rtl-icons' : ''"><img src="assets/img/icons-collection.png" /></div>
    </div>
  </div>
</div>
