import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrl: './qr-scanner.component.scss',
})
export class QrScannerComponent {
  qrResultString: string;

  constructor(private dialogRef: MatDialogRef<QrScannerComponent>) {}

  onCodeResult(resultString: any) {
    this.qrResultString = resultString;
    this.dialogRef.close(this.resp(true));
  }

  resp(ok: boolean = false) {
    return { ok, password: this.qrResultString };
  }
}
