import { Component, Input, OnInit } from '@angular/core';
import {
  getNameAcronym,
  getFullName,
  ordinalSuffix,
  isUserSameAsLoggedInUser,
  compareDate,
} from 'src/app/utility/common-logic';
import { GreetingType, PeriodType } from 'src/app/utility/enum';
import dayjs from 'dayjs';
import { formatDate } from '@angular/common';
import { Store } from '@ngrx/store';
import { AuthData } from 'src/app/utility/model/token-auth';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/services/lang.service';
import { environment } from 'src/environments/environment';
import { PostPollResult } from 'src/app/utility/model/post-poll';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-greetings',
  templateUrl: './greetings.component.html',
  styleUrl: './greetings.component.scss',
})
export class GreetingsComponent {
  @Input() item: PostPollResult | undefined;

  authData: Partial<AuthData> = {};
  GreetingType = GreetingType;
  greeting = environment.settings.greeting;
  img = environment.url.img;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private lang: LangService,
  ) {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
  }

  get anniversaryDuration() {
    if (this.item?.greeting_info?.type === GreetingType.anniversary) {
      return Number(this.item?.greeting_info?.years_completed);
    }
    return null;
  }

  get key() {
    // for Birthday
    let currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
    if (this.item?.greeting_info?.type === GreetingType.birthday) {
      if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.past.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.past.othersLogin';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.present.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.present.othersLogin';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.future.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.future.othersLogin';
      }
    }

    // for anniversary
    if (this.item?.greeting_info?.type === GreetingType.anniversary) {
      if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.past.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.past.othersLogin';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.present.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.present.othersLogin';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.future.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        !isUserSameAsLoggedInUser(
          this.authData,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.future.othersLogin';
      }
    }
    return '';
  }

  get ordinalSuffix() {
    return this.lang.lang === 'en'
      ? ordinalSuffix(this.anniversaryDuration)
      : '';
  }

  getDate() {
    if (!!this.item?.greeting_info?.date) {
      return formatDate(String(this.item?.greeting_info?.date), 'dd', 'en_US');
    }
    return '';
  }

  get fullName() {
    return getFullName(this.authData, {
      first_name: this.item?.greeting_info?.user_first_name,
      last_name: this.item?.greeting_info?.user_last_name,
      email: this.item?.greeting_info?.user_email,
    });
  }

  get acronym() {
    return getNameAcronym(this.fullName);
  }

  get iconSmall() {
    if (this.item?.greeting_info?.type === GreetingType.anniversary) {
      return 'assets/img/anniversary-icon.svg';
    } else if (this.item?.greeting_info?.type === GreetingType.birthday) {
      return 'assets/img/birthday-icon.svg';
    }
    return '';
  }

  get getFullName() {
    return (
      this.item?.greeting_info?.user_first_name +
      ' ' +
      this.item?.greeting_info?.user_last_name
    )?.trim();
  }
}
