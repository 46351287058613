import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RewardCategory } from '../../../utility/model/reward-category';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent {
  @Input() list: RewardCategory[] = [];
  @Input() rc: RewardCategory | null | undefined = null;
  @Output() rcChange = new EventEmitter<RewardCategory | null>();

  constructor(private translate: TranslateService) {}

  select(rc: RewardCategory) {
    this.rc = rc;
    this.rcChange.emit(this.rc);
  }

  boxClass(rc: RewardCategory) {
    return !!this.rc && this.rc.slug === rc.slug
      ? 'box--active'
      : 'box--inactive';
  }

  textClass(rc: RewardCategory) {
    return !!this.rc && this.rc.slug === rc.slug
      ? 'text--active'
      : 'text--inactive';
  }

  categoryName(res: any) {
    const key = 'server.' + res?.name;
    const name = this.translate.instant(key);
    if (name !== key) {
      return name;
    }
    return res.name;
  }
}
