<div class="container pt-3">
  <div class="row">
    <div class="col-12 text-center">
      <label class="heading">{{ "common.warning" | translate }}</label>
      <img
        (click)="closeModal()"
        class="close-icon float-end"
        src="assets/img/close.png"
      />
    </div>
  </div>
  <hr />
  <div class="row otp-screen text-center">
    <div class="col-12">
      <img class="mb-3 mx-auto d-block w-25" src="/assets/img/alert.png" />
    </div>
  </div>
  <div class="row text-center">
    <div class="col-md-12">
      <h5>{{ "common.warningText" | translate }}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <button
        (click)="activeModal.close('Close click')"
        class="btn w-50 py-2 alert-btn d-block mt-3 mb-4 mx-auto text-white"
      >
        {{ "common.acknowledge" | translate }}
      </button>
    </div>
  </div>
</div>
