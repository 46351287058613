import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGif } from '@giphy/js-types';
import { Image } from 'src/app/utility/model/post-poll';
import { ImgFile } from 'src/app/utility/model/images';
import { ScreenSetting } from 'src/app/utility/model/organization';

@Component({
  selector: 'app-image-btn',
  templateUrl: './image-btn.component.html',
  styleUrls: ['./image-btn.component.scss'],
})
export class ImageBtnComponent {
  @Input() eCard: Image | undefined;
  @Input() gif: IGif | undefined;
  @Input() gif2: string | null | undefined;
  @Input() images: ImgFile[] | undefined;
  @Input() enableECard = false;
  @Input() disableGIF = '';
  @Input() disableImages = '';
  @Input() customLabelUploadImages = '';
  @Input() screenSetting: ScreenSetting;
  @Input() only1Image = '';
  @Output() onOpenECard = new EventEmitter();
  @Output() onOpenGIF = new EventEmitter();
  @Output() onOpenImage = new EventEmitter();
  @Output() removeECard = new EventEmitter();
  @Output() removeGif = new EventEmitter();
  @Output() removeImgFile = new EventEmitter<ImgFile>();
}
